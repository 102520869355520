import { Spinner, Box } from "@chakra-ui/react"
import colors from "../../theme/colors"
const PendingSpinner = () => {

    return (
        <Box
            alignItems="center"
            d="flex"
            py="50px"
        >
            <Spinner d="flex" margin="auto" size="xl" />
        </Box>

    )
}
export default PendingSpinner