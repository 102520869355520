import { Input, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { Box, Heading } from '@chakra-ui/layout';
import { BiPlus, FiChevronDown, IoMdClose } from 'react-icons/all';
import colors from '../../theme/colors';
import FocusLock from 'react-focus-lock';
import { Button, IconButton } from '@chakra-ui/button';
import React, { useEffect, useRef, useState } from 'react';

const ConnectSocialMediaPopover = ({ items, parentCallback }) => {

  const initialFocusRef = useRef();
  const [showConnectMediaPopover, setShowConnectMediaPopover] = useState(false);
  const [activeItem, setActiveItem] = useState(items.length > 0 ? items[0] : { key: null });
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [telegram, setTelegram] = useState("");

  const submitSocial = () => {
    const data = [
      {
        key: "facebook",
        value: facebook
      },
      {
        key: "instagram",
        value: instagram
      },
      {
        key: "twitter",
        value: twitter
      },
      {
        key: "linkedin",
        value: linkedin
      },
      {
        key: "telegram",
        value: telegram
      },
    ]
    parentCallback("a", "b", data)
  }
  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement="bottom"
      isOpen={showConnectMediaPopover}
      onOpen={() => setShowConnectMediaPopover(true)}
      onClose={() => setShowConnectMediaPopover(false)}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Box d="flex" color="#fff" style={{ gap: 5 }} cursor="pointer" userSelect="none">
          {showConnectMediaPopover ? (
            <IoMdClose size={16} color={colors.secondary} />
          ) : (
            <BiPlus size={16} color={colors.secondary} />
          )}
          <Heading as="h6" size="xs">
            Connect Social Media
          </Heading>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        color="white"
        bg="linear-gradient(180deg, #2A2E3D 0%, #3C4254 100%)"
        borderColor="transparent"
        outline="none"
        boxShadow="none !important"
      >
        <FocusLock returnFocus persistentFocus={false}>
          <Box
            d="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="24px 0"
          >
            <Heading size="sm" padding="0 16px">
              Choose a Social Network
            </Heading>
            <Box
              d="flex"
              mt="35px"
              padding="0 16px"
              style={{ gap: 8 }}
            >
              {items.map((tab, tabIdx) => (
                <IconButton
                  aria-label={tab.key}
                  key={tab.key + tabIdx}
                  icon={tab.icon}
                  bgColor={tab.key === activeItem.key ? colors.secondary : '#323A51'}
                  rounded="full"
                  color={tab.key === activeItem.key ? colors.text : "rgba(255, 255, 255, .5)"}
                  onClick={() => setActiveItem(activeItem.key !== tab.key ? tab : { key: null })}
                  boxShadow="none !important"
                  _hover={{
                    bg: colors.secondary,
                    color: colors.text,
                  }}
                />
              ))}
            </Box>
            <Box
              d="flex"
              mt="35px"
              mb="15px"
              pb="15px"
              borderBottom="1px solid #5F6780"
              w="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                color="#fff"
              >
                <FiChevronDown size={24} />
              </Box>
            </Box>

            {activeItem.key == "facebook" && (
              <>
                <Heading size="sm" padding="0 16px">
                  {activeItem.title}
                </Heading>
                <Box
                  p="12px 16px 17px 16px"
                >
                  <Input
                    value={facebook}
                    placeholder={activeItem.placeholder}
                    size="lg"
                    backgroundColor="white"
                    borderRadius="5px"
                    border="none"
                    boxShadow="none !important"
                    color={colors.text}
                    onChange={(e) => { setFacebook(e.target.value) }}
                  />
                </Box>

                <Box>

                </Box>
              </>
            )}

            {activeItem.key == "twitter" && (
              <>
                <Heading size="sm" padding="0 16px">
                  {activeItem.title}
                </Heading>
                <Box
                  p="12px 16px 17px 16px"
                >
                  <Input
                    value={twitter}
                    placeholder={activeItem.placeholder}
                    size="lg"
                    backgroundColor="white"
                    borderRadius="5px"
                    border="none"
                    boxShadow="none !important"
                    color={colors.text}
                    onChange={(e) => { setTwitter(e.target.value) }}
                  />
                </Box>

                <Box>

                </Box>
              </>
            )}

            {activeItem.key == "instagram" && (
              <>
                <Heading size="sm" padding="0 16px">
                  {activeItem.title}
                </Heading>
                <Box
                  p="12px 16px 17px 16px"
                >
                  <Input
                    value={instagram}
                    placeholder={activeItem.placeholder}
                    size="lg"
                    backgroundColor="white"
                    borderRadius="5px"
                    border="none"
                    boxShadow="none !important"
                    color={colors.text}
                    onChange={(e) => { setInstagram(e.target.value) }}
                  />
                </Box>

                <Box>

                </Box>
              </>
            )}

            {activeItem.key == "linkedin" && (
              <>
                <Heading size="sm" padding="0 16px">
                  {activeItem.title}
                </Heading>
                <Box
                  p="12px 16px 17px 16px"
                >
                  <Input
                    value={linkedin}
                    placeholder={activeItem.placeholder}
                    size="lg"
                    backgroundColor="white"
                    borderRadius="5px"
                    border="none"
                    boxShadow="none !important"
                    color={colors.text}
                    onChange={(e) => { setLinkedin(e.target.value) }}
                  />
                </Box>

                <Box>

                </Box>
              </>
            )}

            {activeItem.key == "telegram" && (
              <>
                <Heading size="sm" padding="0 16px">
                  {activeItem.title}
                </Heading>
                <Box
                  p="12px 16px 17px 16px"
                >
                  <Input
                    value={telegram}
                    placeholder={activeItem.placeholder}
                    size="lg"
                    backgroundColor="white"
                    borderRadius="5px"
                    border="none"
                    boxShadow="none !important"
                    color={colors.text}
                    onChange={(e) => { setTelegram(e.target.value) }}
                  />
                </Box>

                <Box>

                </Box>
              </>
            )}
            <Button onClick={() => submitSocial()} background={colors.secondary} color={colors.text}>Submit</Button>
          </Box>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

export default ConnectSocialMediaPopover