import React, { useContext, useState } from "react";
import { Box, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import colors from '../theme/colors';
import { pageWidth } from '../theme/styles';
import Navbar from '../components/navbar/Navbar';
import { Link as CLink } from '@chakra-ui/layout';
import { Link as RLink, useHistory, Redirect } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import { auth } from "../firebase/base";
import { signInWithEmailAndPassword } from "firebase/auth"
import { AuthContext } from "../firebase/Auth"
import { useAlert } from "react-alert"
import Validate from "../components/Validate";
import routes from "../constants/routes";
const SignInTalent = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [error, setError] = useState({});

    const history = useHistory();
    const alert = useAlert()

    const { currentUser } = useContext(AuthContext)

    if (currentUser) {
        return <Redirect to={routes.search} />
    }
    const handleLogin = async event => {
        try {
            const errors = valid()
            setError(errors);
            if (Object.keys(errors).length === 0) {
                await signInWithEmailAndPassword(auth, email, password)
            }
        } catch (error) {
            alert.error(error.code)
        }

    }

    const valid = () => {
        const errors = {};
        if (!email) errors.email = "E-Mail field is required.";
        if (!password) errors.password = "Password field is required.";
        return errors;
    }
    const errors = { ...error };
    return (
        <div>
            <Navbar />
            <Breadcrumb />
            <Box
                alignItems="center"
                d="flex"
                backgroundColor={colors.pageBG}
                pt="70px"
            >
                <Box
                    alignItems="center"
                    maxW={pageWidth}
                    margin="auto"
                    width="100%"

                >
                    <Box
                        d="flex"
                        flexDirection="row"
                        alignItems="baseline"
                        mb="24px"
                    >
                        <CLink
                            as={RLink}
                            to={routes.signInRecruiter}
                            fontSize={{ base: "10px", md: "16px" }}
                            fontWeight="bold"
                            textTransform="uppercase"
                            letterSpacing="2px"
                            color={colors.secondary}
                        >
                            &#8592;    SIGN IN AS EMPLOYERS
                        </CLink>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection="row"
                        alignItems="baseline"
                        pb="64px"
                    >
                        <Text fontSize={{ base: "34px", md: "48" }} fontWeight="bold" color="#fff">Sign In as Talent</Text>
                    </Box>
                </Box>
            </Box>
            <Box
                alignItems="center"
                d="flex"
                backgroundImage={colors.linearBG}
            >
                <Box
                    alignItems="center"
                    maxW={pageWidth}
                    margin="auto"
                    width="100%"
                >
                    <Box
                        d="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="baseline"
                        mt="64px"
                        mb="64px"
                    >
                        <FormControl mr={{ md: "5" }}>
                            <Formlabel>Email Adress</Formlabel>
                            <FormInput onChange={(e) => setEmail(e.target.value)} name="email" placeholder="eg. john.doe@gmail.com" type="email" />
                            {errors.email && <Validate message={errors.email} />}
                        </FormControl>
                        <FormControl mt={{ base: "5px" }}>
                            <Formlabel>Password</Formlabel>
                            <FormInput onChange={(e) => setPassword(e.target.value)} name="password" placeholder="******" type="password" />
                            {errors.password && <Validate message={errors.password} />}
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <Box
                alignItems="center"
                d="flex"
                backgroundColor={colors.pageBG}
                pt="56px"
                pb="100px"
            >
                <Box
                    alignItems="center"
                    maxW={pageWidth}
                    margin="auto"
                    width="100%"
                >
                    <Box
                        d="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button onClick={() => handleLogin()} bgColor={colors.secondary} color={colors.text} width="213px" py="30px" mt="32px" >
                            SIGN IN
                        </Button>
                    </Box>
                </Box>
            </Box>
        </div>

    )
}
const FormInput = ({ children, ...props }) => {
    return (
        <Input {...props} focusBorderColor="none" height="72px" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}>
            {children}
        </Input>
    );
};
const Formlabel = ({ children, ...props }) => {
    return (
        <FormLabel {...props} color="#fff" mb="24px" fontWeight="600" >
            {children}
        </FormLabel>
    );
};
export default SignInTalent
