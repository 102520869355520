import * as React from 'react';

function Person({ fill = '#323A51', ...props }) {
  return (
    <svg
      width={34}
      height={41}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.612 0c-5.52 0-10.01 4.49-10.01 10.01s4.49 10.01 10.01 10.01 10.01-4.49 10.01-10.01S22.132 0 16.612 0zm0 17.617c-4.194 0-7.607-3.413-7.607-7.607 0-4.195 3.413-7.608 7.607-7.608 4.195 0 7.608 3.413 7.608 7.608 0 4.194-3.413 7.607-7.608 7.607zM32.445 35.872c-1.18-7.763-7.858-13.45-15.704-13.45h-.312c-7.828 0-14.475 5.725-15.65 13.45L0 41h33.225l-.78-5.128zm-17.034 2.726H2.795l.36-2.365a13.425 13.425 0 017.91-10.289l4.346 6.722v5.932zm-1.989-13.432c.98-.225 1.989-.342 3.007-.342h.312c1.036 0 2.062.12 3.057.35l-3.186 4.925-3.19-4.933zm4.392 13.432v-5.932l4.342-6.715a13.405 13.405 0 017.914 10.282l.36 2.365H17.814z"
        fill={fill}
      />
    </svg>
  );
}

export default Person;
