import React, { useCallback, useContext, useState } from "react";
import { auth, db } from "../firebase/base";
import { Box, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import colors from '../theme/colors';
import { fontSizes, pageWidth } from '../theme/styles';
import Navbar from '../components/navbar/Navbar';
import { Link as CLink } from '@chakra-ui/layout';
import { Link as RLink, useHistory, Redirect } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import { AuthContext } from "../firebase/Auth"
import Validate from "../components/Validate";
import { getDoc, doc } from "firebase/firestore";
import { Industries, State } from "../lib/lib"
import routes from "../constants/routes";
import { useAlert } from "react-alert";
import video3 from "../assets/video/video3.mp4"
const SignUpRecruiter = () => {
    const [email, setEmail] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [number, setNumber] = useState("");
    const [company, setCompany] = useState("");
    const [industry, setIndustry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [password, setPassword] = useState("")
    const [repassword, setRepassword] = useState("")
    const [error, setError] = useState({})
    const history = useHistory();

    const alert = useAlert()
    const { currentUser } = useContext(AuthContext)
    if (currentUser) {
        return <Redirect to={routes.search} />
    }
    const data = {
        email,
        first,
        last,
        number,
        company,
        industry,
        state,
        city,
        password,
        userType: "recruiter"
    }
    const handleLogin = async event => {
        const errors = valid()
        setError(errors);

        if (Object.keys(errors).length === 0) {
            const docRef = doc(db, "users", email);
            const docSnap = await getDoc(docRef);
            if (!docSnap.data()) {
                history.push({ pathname: "/sign-up-2", state: { data } });
            }
            else {
                alert.error("Already using email")
            }
        }

    }
    const valid = () => {
        const errors = {};
        if (!email) errors.email = "E-Mail field is required.";
        if (!first) errors.first = "Firs Name field is required.";
        if (!last) errors.last = "Last Name field is required.";
        if (!state) errors.state = "State field is required.";
        if (!city) errors.city = "City field is required.";
        if (!company) errors.company = "Company Name field is required.";
        if (!industry) errors.industry = "Company industry field is required.";
        if (password.length < 8) errors.password = "Passwords must be at least 8 characters in length";
        if (repassword.length < 8) errors.repassword = "Passwords must be at least 8 characters in length";
        if (password !== repassword) errors.password = "Passwords do not match";
        if (password !== repassword) errors.repassword = "Passwords do not match";
        if (!password) errors.password = "Passwords field is required";
        if (!repassword) errors.repassword = "Confirm password field is required";
        return errors;
    }
    const errors = { ...error };

    return (
        <div>

            <Navbar />
            <Breadcrumb boolean={true} videoUrl={video3} title="Want to know who is behind a resume? Let our AI-Powered & Digital Features Cut your Time-To-Hire & help you discover talent the new way." />
            <form >
                <Box
                    alignItems="center"
                    d="flex"
                    backgroundColor={colors.pageBG}
                    pt="30px"
                >
                    <Box
                        alignItems="center"
                        maxW={pageWidth}
                        margin="auto"
                        width="100%"

                    >
                        {/* <Box mb="20px" padding="20px" lineHeight={{ base: "24px", md: "30px" }} fontSize={{ base: "16px", md: "26px" }} fontWeight="bold" color="#fff" background={colors.linearBG} background={colors.linearBG}>
                            Want to know who is behind a resume?Let our AI-Powered & Digital Features Cut your Time-To-Hire & help you discover talent the new way.
                        </Box> */}

                        <Box
                            d="flex"
                            flexDirection={{ base: "column", md: "row" }}
                            alignItems="baseline"
                        >
                            <Text fontSize={{ base: "34px", md: "48" }} fontWeight="bold" color="#fff">Sign Up as Employer</Text>
                            <CLink as={RLink} to={routes.signInTalent} fontSize={{ base: "24", md: "36" }} fontWeight="bold" color="#fff" opacity="0.35" ml={{ md: "33px" }} >Sign Up as Talent</CLink>
                        </Box>
                        <Box
                            d="flex"
                            flexDirection={{ base: "column", md: "row" }}
                            justifyContent="center"
                            alignItems="baseline"

                            mt="64px"
                        >
                            <FormControl mr={{ md: "5" }}>
                                <Formlabel>Company Name</Formlabel>
                                <FormInput onChange={(e) => setCompany(e.target.value)} name="company" placeholder="Enter text here" type="text" />
                                {errors.company && <Validate message={errors.company} />}
                            </FormControl>
                            <FormControl >
                                <Formlabel>Company Industry</Formlabel>
                                <Select onChange={(e) => setIndustry(e.target.value)} name="industry" height="72px" placeholder="Select an Industry" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)", paddingLeft: "20px" }}>
                                    {Industries.map(x =>
                                        <option value={x}>{x}</option>
                                    )}
                                </Select>
                                {errors.industry && <Validate message={errors.industry} />}
                            </FormControl>
                        </Box>
                        <Box
                            d="flex"
                            flexDirection={{ base: "column", md: "row" }}
                            justifyContent="center"
                            alignItems="baseline"
                            mt="32px"
                            mb="64px"
                        >
                            <FormControl mr={{ md: "5" }}>
                                <Formlabel>State</Formlabel>
                                <Select onChange={(e) => setState(e.target.value)} name="city" height="72px" placeholder="Select an State" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)", paddingLeft: "20px" }}>
                                    {State.map(x =>
                                        <option value={x}>{x}</option>
                                    )}
                                </Select>
                                {errors.state && <Validate message={errors.state} />}
                            </FormControl>
                            <FormControl >
                                <Formlabel>City</Formlabel>
                                <FormInput onChange={(e) => setCity(e.target.value)} name="city" placeholder="Enter text here" type="text" />
                                {errors.city && <Validate message={errors.city} />}
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box
                    alignItems="baseline"
                    d="flex"
                    backgroundImage={colors.linearBG}
                >
                    <Box
                        alignItems="center"
                        maxW={pageWidth}
                        margin="auto"
                        width="100%"
                    >
                        <Box
                            d="flex"
                            flexDirection={{ base: "column", md: "row" }}
                            justifyContent="center"
                            alignItems="center"
                            mt="64px"
                        >
                            <FormControl mr={{ md: "5" }}>
                                <Formlabel>First Name</Formlabel>
                                <FormInput onChange={(e) => setFirst(e.target.value)} name="first" placeholder="Enter text here" type="text" />
                                {errors.first && <Validate message={errors.first} />}
                            </FormControl>
                            <FormControl >
                                <Formlabel>Last Name</Formlabel>
                                <FormInput onChange={(e) => setLast(e.target.value)} name="last" placeholder="Enter text here" type="text" />
                                {errors.last && <Validate message={errors.last} />}
                            </FormControl>
                        </Box>
                        <Box
                            d="flex"
                            flexDirection={{ base: "column", md: "row" }}
                            justifyContent="center"
                            alignItems="baseline"
                            mt="32px"
                        >
                            <FormControl mr={{ md: "5" }}>
                                <Formlabel>Email Address</Formlabel>
                                <FormInput onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Enter text here" type="email" />
                                {errors.email && <Validate message={errors.email} />}
                            </FormControl>
                            <FormControl >
                                <Formlabel>Office Phone Number</Formlabel>
                                <FormInput onChange={(e) => setNumber(e.target.value)} name="number" placeholder="Enter text here" type="text" />
                            </FormControl>
                        </Box>
                        <Box
                            d="flex"
                            flexDirection={{ base: "column", md: "row" }}
                            justifyContent="center"
                            alignItems="baseline"
                            mt="32px"
                            mb="64px"
                        >
                            <FormControl mr={{ md: "5" }}>
                                <Formlabel>Password</Formlabel>
                                <FormInput onChange={(e) => setPassword(e.target.value)} name="password" placeholder="Enter text here" type="password" />
                                {errors.password && <Validate message={errors.password} />}
                            </FormControl>
                            <FormControl >
                                <Formlabel>Confirm Password</Formlabel>
                                <FormInput onChange={(e) => setRepassword(e.target.value)} name="repassword" placeholder="Enter text here" type="password" />
                                {errors.repassword && <Validate message={errors.repassword} />}
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box
                    alignItems="center"
                    d="flex"
                    backgroundColor={colors.pageBG}
                    pt="56px"
                    pb="100px"
                >
                    <Box
                        alignItems="center"
                        maxW={pageWidth}
                        margin="auto"
                        width="100%"
                    >
                        <Box
                            d="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                        >
                            <Text fontSize={fontSizes.normal} color="#fff">By Signing up, you agree to our <b>Terms and Conditions</b> and<br /><b> Privacy Policy</b></Text>
                        </Box>
                        <Box
                            d="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button onClick={() => handleLogin()} bgColor={colors.secondary} color={colors.text} px="20px" py="30px" mt="32px" >
                                SIGN UP AS EMPLOYER
                            </Button>
                        </Box>
                        <Box
                            d="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            mt="28px"
                        >
                            <Text fontSize={fontSizes.normal} color="#fff">Aready have an account? </Text><CLink as={RLink} to={routes.signInRecruiter} fontSize={fontSizes.normal} color={colors.secondary}>SIGN IN</CLink>

                        </Box>
                    </Box>
                </Box>
            </form>
        </div>

    )
}
const FormInput = ({ children, ...props }) => {

    return (
        <Input {...props} focusBorderColor="none" height="72px" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}>
            {children}
        </Input>
    );
};

const Formlabel = ({ children, ...props }) => {
    return (
        <FormLabel {...props} color="#fff" mb="24px" fontWeight="600" >
            {children}
        </FormLabel>
    );
};
export default SignUpRecruiter
