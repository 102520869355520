import { IconButton } from '@chakra-ui/button';
import React from 'react';
import colors from '../../theme/colors';

const Profileicon = ({ Icon, bgColor = '#fff',to, ...props }) => {
    return (
        <IconButton
            mr="13px"
            bgColor={bgColor}
            rounded="full"
            color={colors.primary}
            icon={Icon}
            {...props}
            as="a"
            href={to}
            target="_blank"
            cursor="pointer"
        />
    );
};

export default Profileicon;
