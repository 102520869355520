

import React, { useEffect, useState, useContext, Fragment } from "react";
import { auth, db } from "../firebase/base";
import { getDoc, doc } from "firebase/firestore";
import Container from '../components/Container';
import { Box, Heading, Text } from '@chakra-ui/layout';
import { fontSizes, pageWidth } from '../theme/styles';
import colors from '../theme/colors';
import { Button } from '@chakra-ui/button';
import TalentProfileTop from '../components/TalentProfileTop';
import RecruiterProfileTop from '../components/RecruiterProfileTop';
import { Popover, PopoverBody, PopoverTrigger, PopoverContent, AspectRatio, Spinner } from "@chakra-ui/react"
import { IoIosArrowDown, FiPlay } from "react-icons/all"
import { AuthContext } from "../firebase/Auth"
import ReactPlayer from "react-player/lazy"
import PendingSpinner from "../components/Spinner/spinner.js";
import { useHistory } from "react-router";
import { useAlert } from "react-alert";


const UploadVideo = (props) => {
    let index = 0
    const [i, setI] = useState(0)
    const [arrays, setArrays] = useState([])
    const [translate, setTranslate] = useState([])
    const [subtitleShow, setSubtitle] = useState(false)
    const [subtitleValue, setSubtitleValue] = useState("")
    const [disabled, setDisabled] = useState(true)
    const [loop, setLoop] = useState(false)

    const {
        title = '',
        text = '',
        language = props.language,
        url = props.url,
        subtitle = props.subtitle,
        parentChakraProps,
        parentChakraStyle,
    } = props;

    let subtitles = JSON.parse(subtitle).filter(String)
    useEffect(async () => {
        if (!loop) {
            setTimeout(() => {
                setDisabled(false)
                setLoop(true)
            }, 5000);
        }
        if (arrays.length < 1) {
            JSON.parse(subtitle).map(x => setArrays(oldArray => [...oldArray, x]))
        }

        if (subtitleShow && i < subtitles.length) {
            // const res = await fetch("https://libretranslate.de/translate", {
            //     method: "POST",
            //     body: JSON.stringify({
            //         q: subtitles[i] ? subtitles[i] : "",
            //         source: "en",
            //         target: "en"
            //     }),
            //     headers: { "Content-Type": "application/json" }
            // });

            // let value = await res.json()
            setSubtitleValue(subtitles[i])
            setTranslate(oldArray => [...oldArray, subtitles[i]])
        }
    }, [i])


    useEffect(() => {
        const interval = setInterval(() => {
            setI(i + 1)
        }, 4700);
        return () => clearInterval(interval);
    }, [i])

    const config = {
        attributes: {
            disablePictureInPicture: true,
            controlsList: 'nodownload'
        }
    };
    const playSubtitle = () => {
        setSubtitle(true)
        setI(0)
    }

    return (
        <Box
            d="flex"
            flexDirection="column"
            w={{ base: "unset", md: "37.5vw" }}
            mt="92px"

            style={{
                gap: 20,
                ...(parentChakraStyle || {}),
            }}
            {...parentChakraProps}

        >
            {/* Upload(ed) video area */}
            <Box
                className={disabled && "disabledbutton"}
                d="block"
                w="100%"
                h="16.805555556vw"
                minH="240px"
                borderRadius="10px"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
            >
                <AspectRatio maxH="240px" ratio={1}>
                    <ReactPlayer config={config} onStart={() => playSubtitle()} playing playIcon={<FiPlay fontSize="70px" />} light url={url} width="100%" height="240px" onContextMenu={e => e.preventDefault()} />
                </AspectRatio>
            </Box>

            {/* Info area */}
            <Box
                d="flex"
                flexDirection="column"
                w="100%"
                style={{ gap: 5 }}
                position="relative"
            >
                <Box borderRadius="5px" background={colors.linearBG}>
                    <Heading as="h4" size="md" color="#fff" textAlign="center">
                        {subtitleShow && subtitleValue}
                    </Heading>
                </Box>
                <Heading textAlign="center" as="h4" size="lg" color="#fff">
                    {title}
                </Heading>
                <Box d="flex" justifyContent="center" >
                    <Popover gutter="false" preventOverflow="bottom">
                        <PopoverTrigger>
                            <Button _hover="none" background="transparent" color="#fff"><IoIosArrowDown /></Button>
                        </PopoverTrigger>
                        <PopoverContent className="popcontent" w="100%" border="none" bg={colors.pageBG} maxWidth={{ base: "200px", md: "520px" }}>
                            <PopoverBody pl="15px" py="18px" maxH="322px" overflow="scroll-y">{JSON.parse(subtitle).join(" ")}</PopoverBody>
                        </PopoverContent>

                    </Popover>
                </Box>
            </Box>
        </Box>
    );
};

const Profile = ({ match }) => {
    const { currentUser } = useContext(AuthContext)
    const [user, setUser] = useState(currentUser);
    const [pending, setPending] = useState(true);
    const alert = useAlert()
    const history = useHistory()
    useEffect(async () => {
        if (match.params.id) {
            const docRef = doc(db, "users", match.params.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.data()) {
                setUser(docSnap.data())
                setPending(false)
            }
            else {
                alert.error("User Not Found")
                history.push("/")
            }
        }
    }, [match]);


    return (
        pending ? <PendingSpinner /> :
            (
                < Container >
                    <Fragment>
                        {/* Profile image and profile info area */}
                        {user.userType == 'talent' ? <TalentProfileTop currentUser={user} /> : <RecruiterProfileTop currentUser={user} />}

                        {/* Upload(ed) video(s) rows */}
                        <Box
                            d="flex"
                            justifyContent="center"
                            alignItems="center"
                            backgroundColor={colors.pageBG}
                        >
                            <Box
                                d="flex"
                                flexDirection="column"
                                w="100%"
                                maxW={pageWidth}
                            >
                                {/* Upload(ed) video(s) row */}

                                <Box
                                    d="flex"
                                    justifyContent={{ base: "center", md: "space-between" }}
                                    w="100%"
                                    paddingBottom="48px"
                                    marginBottom="60px"
                                    flexFlow="wrap"

                                >
                                    {user.videos && user.videos.map && user.videos.map((video, index) =>
                                        <UploadVideo
                                            key={index}
                                            title="5 Year Goals"
                                            url={video}
                                            subtitle={user.subtitles[index]}
                                            language={"JSON.parse(currentUser.city).code.toLowerCase()"}
                                            title={user.videoTitle[index]}
                                        />

                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Fragment>
                </Container >
            )
    )
}

export default Profile
