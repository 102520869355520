import React, { Fragment, useContext, useState } from 'react';
import { Box, Heading, Stack, Text, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, Button, Input, FormLabel, Select } from '@chakra-ui/react';
import colors from '../../theme/colors';
import { pageWidth } from '../../theme/styles';
import { ImFacebook, ImTwitter, BiPlus, FaInstagram, GoPencil, RiLinkedinFill, FaTelegramPlane } from 'react-icons/all';
import LinkWithChakra from '../LinkWithChakra';
import ConnectSocialMediaPopover from './ConnectSocialMediaPopover';
import { State } from '../../lib/lib';


const SocialMediaTabs = [
  {
    key: 'facebook',
    icon: <ImFacebook />,
    title: "Enter your Facebook username",
    placeholder: 'facebook.com/'
  },
  {
    key: 'twitter',
    icon: <ImTwitter />,
    title: "Enter your Twitter username",
    placeholder: "twitter.com/",
  },
  {
    key: 'instagram',
    icon: <FaInstagram />,
    title: "Enter your Instagram username",
    placeholder: "instagram.com/",
  },
  {
    key: 'linkedin',
    icon: <RiLinkedinFill />,
    title: "Enter your LinkedIn username",
    placeholder: "linkedin.com/in/",
  },
  {
    key: 'telegram',
    icon: <FaTelegramPlane />,
    title: "Enter your Telegram username",
    placeholder: "",
  },
];

const ProfileImageAndPInfo = ({ currentUser, socialMedia, setProfileAvatar }) => {
  const [edit, setEdit] = useState(false)
  const [first, setFirst] = useState(currentUser.first);
  const [last, setLast] = useState(currentUser.last);
  const [state, setState] = useState(currentUser.state);
  const [city, setCity] = useState(currentUser.city);
  const [website, setWebsite] = useState("");


  const callback = (key, value, keys) => {
    socialMedia(key, value, keys)
  }
  return (

    <Box
      d="flex"
      w="100%"
      alignItems="center"
      justifyContent="center"
      paddingTop={68}
      bgColor={colors.pageBG}
    >
      <Box
        d="flex"
        w="100%"
        maxW={pageWidth}
        padding="32px 0"
        style={{ gap: 50 }}
        borderBottom="1px solid #8D93A8"
        marginBottom="48px"
        flexDirection={{ base: "column", sm: "row" }}
      >
        {currentUser && <Fragment>
          <Box
            d="flex"
          >
            {/* Profile image area */}
            <label for="uploadAvatar">
              <Box
                id="pp"
                d="flex"
                backgroundSize="161px !important"
                backgroundRepeat="no-repeat "
                background={colors.linearBG}
                w={161}
                h={161}
                justifyContent="center"
                alignItems="center"
                borderRadius={12}
                cursor="pointer"
              >
                <BiPlus size={32} color="#fff" />
              </Box>
              <input type="file" id="uploadAvatar" style={{ display: "none" }} onChange={setProfileAvatar} />
            </label>
          </Box>

          {/* Profile info area */}
          <Box
            d="flex"
            flex={1}
            flexDirection="column"
            alignItems="stretch"
            justifyContent="space-between"
          >
            {/* User name, address and edit profile area */}
            <Box
              d="flex"
              justifyContent="space-beween"
              alignItems="flex-start"
              boxSizing="border-box"
              padding="5px 0"
            >
              <Box
                w="100%"
                h="auto"
              >
                <Stack color="#FFFFFF">
                  {!edit ? <Heading as="h3" size="2xl">
                    {first + " " + last.substring(0, 3) + "."}
                  </Heading> :
                    <Fragment>
                      <Box>
                        <FormLabel color="#fff" fontWeight="600" >
                          First Name
                        </FormLabel>
                        <Input onChange={(e) => { callback("firstname", e.target.value); setFirst(e.target.value) }} type="text" focusBorderColor="none" width="50%" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} />
                      </Box>
                      <Box>
                        <FormLabel color="#fff" fontWeight="600" >
                          Last Name
                        </FormLabel>
                        <Input onChange={(e) => { callback("lastname", e.target.value); setLast(e.target.value) }} type="text" focusBorderColor="none" width="50%" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} />
                      </Box>
                    </Fragment>
                  }

                  <Text fontSize="lg">
                    {!edit ? <Box>{city + ", " + state}<br /><a style={{ textDecoration: "underline", color: "lightblue" }} target="_blank" href={website}>{website}</a></Box> :
                      <Fragment>
                        <Box>
                          <FormLabel color="#fff" fontWeight="600" >
                            State
                          </FormLabel>
                          <Select onChange={(e) => { callback("state", e.target.value); setState(e.target.value) }} type="text" focusBorderColor="none" width="50%" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}>
                            {State.map(x =>
                              <option value={x}>{x}</option>
                            )}
                          </Select>

                        </Box>
                        <Box>
                          <FormLabel color="#fff" fontWeight="600" >
                            City
                          </FormLabel>
                          <Input onChange={(e) => { callback("city", e.target.value); setCity(e.target.value) }} type="text" focusBorderColor="none" width="50%" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} />
                        </Box>
                      </Fragment>
                    }

                  </Text>
                </Stack>
              </Box>
              <Box height="auto">
                <Box
                  onClick={() => setEdit(!edit)}
                  d="flex"
                  w="36px"
                  h="36px"
                  p="0"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="18px"
                  cursor="pointer"
                  bgColor={colors.secondary}
                >
                  <GoPencil />
                </Box>
              </Box>
            </Box>

            {/* Website url and social media area */}
            <Box
              d="flex"
              justifyContent="space-between"
            >
              <Box>
                <LinkWithChakra color="#fff">
                  <Popover>
                    <PopoverTrigger>
                      <Button size="xs" _hover="none" background="transparent">Edit Website URL</Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody> <Input onChange={(e) => { callback("website", e.target.value); setWebsite(e.target.value) }} type="text" focusBorderColor="none" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}></Input>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </LinkWithChakra>
              </Box>
              <Box>
                <ConnectSocialMediaPopover parentCallback={callback} items={SocialMediaTabs} />
              </Box>
            </Box>
          </Box>
        </Fragment>
        }
      </Box>
    </Box>

  );
}

export default ProfileImageAndPInfo;