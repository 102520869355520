import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import React from 'react';
import colors from '../../theme/colors';
import { fontSizes } from '../../theme/styles';

const SignUpCard = ({
  title = '',
  btnTitle = '',
  desc = '',
  icon = null,
  onClick = () => {},
  buttonBg = colors.ternary,
}) => {
  return (
    <Box
      borderRadius="lg"
      backgroundColor="#fff"
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      p="10"
      color={colors.ternary}
      mx="5"
      w="260px"
      
      marginY={{ base: '5', md: '2' }}
    >
      {icon}
      <Text fontWeight="600" my="2" fontSize={fontSizes.medium}>
        {title}
      </Text>
      <Text my="2" fontSize={fontSizes.text}>
        {desc}
      </Text>
      <Button
        onClick={onClick}
        mt="3"
        bgColor={buttonBg}
        color="#fff"
        fontSize={fontSizes.small}
      >
        {btnTitle}
      </Button>
    </Box>
  );
};

export default SignUpCard;
