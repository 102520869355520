import { Image } from '@chakra-ui/image';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';

const Logo = ({ height = 60, width = 56 }) => {
  return (
    <Link to="/">
      <Image src={logo} sizes="10px" height={`${height}px`}  />
    </Link>
  );
};

export default Logo;
