export const Industries = [
    "Abortion Policy / Pro - Abortion Rights",
    "Accountants",
    "Advertising / Public Relations",
    "Aerospace, Defense Contractors",
    "Agribusiness",
    "Agricultural Services & amp; Products",
    "Air Transport",
    "Air Transport Unions",
    "Airlines",
    "Alcoholic Beverages",
    "Alternative Energy Production & amp; Services",
    "Architectural Services",
    "Attorneys / Law Firms",
    "Auto Dealers",
    "Auto Dealers, Japanese",
    "Auto Manufacturers",
    "Automotive",
    "Banking, Mortgage",
    "Banks, Commercial",
    "Banks, Savings & amp; Loans",
    "Bars & amp; Restaurants",
    "Beer, Wine & amp; Liquor",
    "Books, Magazines & amp; Newspapers",
    "Broadcasters, Radio / TV",
    "Builders / General Contractors",
    "Builders / Residential",
    "Building Materials & amp; Equipment",
    "Building Trade Unions",
    "Business Associations",
    "Business Services",
    "Cable & amp; Satellite TV Production & amp; Distribution",
    "Candidate Committees",
    "Candidate Committees, Democratic",
    "Candidate Committees, Republican",
    "Car Dealers",
    "Car Dealers, Imports",
    "Car Manufacturers",
    "Casinos / Gambling",
    "Cattle Ranchers / Livestock",
    "Chemical & amp; Related Manufacturing",
    "Chiropractors",
    "Civil Servants / Public Officials",
    "Clergy & amp; Religious Organizations",
    "Clothing Manufacturing",
    "Coal Mining",
    "Colleges, Universities & amp; Schools",
    "Commercial Banks",
    "Commercial TV & amp; Radio Stations",
    "Communications / Electronics",
    "Computer Software",
    "Conservative / Republican",
    "Construction",
    "Construction Services",
    "Construction Unions",
    "Credit Unions",
    "Crop Production & amp; Basic Processing",
    "Cruise Lines",
    "Cruise Ships & amp; Lines",
    "Dairy",
    "Defense",
    "Defense Aerospace",
    "Defense Electronics",
    "Defense / Foreign Policy Advocates",
    "Democratic Candidate Committees",
    "Democratic Leadership PACs",
    "Democratic / Liberal",
    "Dentists",
    "Doctors & amp; Other Health Professionals",
    "Drug Manufacturers",
    "Education",
    "Electric Utilities",
    "Electronics Manufacturing & amp; Equipment",
    "Electronics, Defense Contractors",
    "Energy & amp; Natural Resources",
    "Entertainment Industry",
    "Environment",
    "Farm Bureaus",
    "Farming",
    "Finance / Credit Companies",
    "Finance, Insurance & amp; Real Estate",
    "Food & amp; Beverage",
    "Food Processing & amp; Sales",
    "Food Products Manufacturing",
    "Food Stores",
    "For - profit Education",
    "For - profit Prisons",
    "Foreign & amp; Defense Policy",
    "Forestry & amp; Forest Products",
    "Foundations, Philanthropists & amp; Non - Profits",
    "Funeral Services",
    "Gambling & amp; Casinos",
    "Gambling, Indian Casinos",
    "Garbage Collection / Waste Management",
    "Gas & amp; Oil",
    "General Contractors",
    "Government Employee Unions",
    "Government Employees",
    "Gun Control",
    "Gun Rights",
    "Health",
    "Health Professionals",
    "Health Services / HMOs",
    "Hedge Funds",
    "HMOs & amp; Health Care Services",
    "Home Builders",
    "Hospitals & amp; Nursing Homes",
    "Hotels, Motels & amp; Tourism",
    "Human Rights",
    "Ideological / Single - Issue",
    "Indian Gaming",
    "Industrial Unions",
    "Insurance",
    "Internet",
    "Israel Policy",
    "Labor",
    "Lawyers & amp; Lobbyists",
    "Lawyers / Law Firms",
    "Leadership PACs",
    "LGBTQIA Rights & amp; Issues",
    "Liberal / Democratic",
    "Liquor, Wine & amp; Beer",
    "Livestock",
    "Lobbyists",
    "Lodging / Tourism",
    "Logging, Timber & amp; Paper Mills",
    "Manufacturing, Misc",
    "Marijuana",
    "Marine Transport",
    "Meat processing & amp; products",
    "Medical Supplies",
    "Mining",
    "Misc Business",
    "Misc Finance",
    "Misc Manufacturing & amp; Distributing",
    "Misc Unions",
    "Miscellaneous Defense",
    "Miscellaneous Services",
    "Mortgage Bankers & amp; Brokers",
    "Motion Picture Production & amp; Distribution",
    "Music Production",
    "Natural Gas Pipelines",
    "Newspaper, Magazine & amp; Book Publishing",
    "Non - profits, Foundations & amp; Philanthropists",
    "Nurses",
    "Nursing Homes / Hospitals",
    "Nutritional & amp; Dietary Supplements",
    "Oil & amp; Gas",
    "Other",
    "Payday Lenders",
    "Pharmaceutical Manufacturing",
    "Pharmaceuticals / Health Products",
    "Phone Companies",
    "Physicians & amp; Other Health Professionals",
    "Postal Unions",
    "Poultry & amp; Eggs",
    "Power Utilities",
    "Printing & amp; Publishing",
    "Private Equity & amp; Investment Firms",
    "Pro - Israel",
    "Professional Sports, Sports Arenas & amp; Related Equipment & amp; Services",
    "Progressive / Democratic",
    "Public Employees",
    "Public Sector Unions",
    "Publishing & amp; Printing",
    "Radio / TV Stations",
    "Railroads",
    "Real Estate",
    "Record Companies / Singers",
    "Recorded Music & amp; Music Production",
    "Recreation / Live Entertainment",
    "Religious Organizations / Clergy",
    "Republican Candidate Committees",
    "Republican Leadership PACs",
    "Republican / Conservative",
    "Residential Construction",
    "Restaurants & amp; Drinking Establishments",
    "Retail Sales",
    "Retired",
    "Savings & amp; Loans",
    "Schools / Education",
    "Sea Transport",
    "Securities & amp; Investment",
    "Special Trade Contractors",
    "Sports, Professional",
    "Steel Production",
    "Stock Brokers / Investment Industry",
    "Student Loan Companies",
    "Sugar Cane & amp; Sugar Beets",
    "Teachers Unions",
    "Teachers / Education",
    "Telecom Services & amp; Equipment",
    "Telephone Utilities",
    "Textiles",
    "Timber, Logging & amp; Paper Mills",
    "Tobacco",
    "Transportation",
    "Transportation Unions",
    "Trash Collection / Waste Management",
    "Trucking",
    "TV / Movies / Music",
    "TV Production",
    "Unions",
    "Unions, Airline",
    "Unions, Building Trades",
    "Unions, Industrial",
    "Unions, Misc",
    "Unions, Public Sector",
    "Unions, Teacher",
    "Unions, Transportation",
    "Universities, Colleges & amp; Schools",
    "Vegetables & amp; Fruits",
    "Venture Capital",
    "Waste Management",
    "Wine, Beer &  Liquor",
    "Women Issues",
    "Other"

];

export const State = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "IllinoisIndiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "MontanaNebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "PennsylvaniaRhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
]