import { Box, Text } from '@chakra-ui/layout';
import { AspectRatio } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import colors from '../theme/colors';
import { fontSizes, pageWidth } from '../theme/styles';
import video1 from "../assets/video/video1.mp4"
import ReactPlayer from 'react-player/youtube';
const Breadcrumb = ({ title, boolean, videoUrl }) => {
    const [video, setVideo] = useState()
    const [i, setI] = useState(1)
    useEffect(() => {
        setI(i + 1)
    }, [])
    useEffect(() => {
        setVideo(document.getElementById("myVideo"))
        if (video) {
            video.addEventListener("mouseenter", () => {
                video.controls = true
            });
            video.addEventListener("mouseleave", () => {
                video.controls = false
            });

        }
    }, [i])

    return (
        <Box
            alignItems="center"
            d="flex"
            backgroundImage="linear-gradient(180deg, #21273E 0%, #2B334B 100%)"
        >
            <Box
                alignItems="center"
                margin="auto"
                width="100%"
            >
                <Box
                    d="flex"
                    justifyContent={title ? "flex-end" : "flex-start"}
                    flexDirection={["column-reverse", "column-reverse", "column-reverse", "row"]}
                    alignItems="center"
                    height="100%"
                >
                    <Box
                        lineHeight={{ md: "58px" }}
                        maxW={pageWidth}

                    >
                        {title ?
                            <Text my={{ base: "10px", md: "0" }} ml={{ base: "0", md: "50px", xl: "125px" }} mr={{ base: "0", md: "100px", xl: "150px" }} fontSize={{ base: "20px", md: "20", xl: "26px" }} lineHeight="40px" fontWeight="bold" color="#fff">{title}</Text> :
                            <Text p="40px" ml={{ md: "100px" }} fontSize={{ base: "24px", md: "48" }} fontWeight="bold" color="#fff">Creating bridges between <Text fontSize={{ base: "24px", md: "48" }} fontWeight="bold" color={colors.secondary}>Businesses and Employees</Text></Text>
                        }
                    </Box>
                    {/* <Image ml={{ sm: "150px" }} height={{ base: "150px", md: "100%" }} src={patterns} /> */}
                    {boolean &&
                        <Box d="flex" m="auto" w="100%" h={{ base: "260px", sm: "260px", md: "unset", xl: "unset" }} justifyContent="end" >
                            <video
                                id="myVideo"
                                src={videoUrl}
                                muted
                                autoPlay
                                style={{ height: "300px" }}
                            />
                        </Box>
                    }
                </Box>

            </Box>
        </Box >

    )
}



export default Breadcrumb