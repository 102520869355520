import React, { Fragment, useCallback, useContext, useState, useRef, useEffect } from "react";
import { useReactMediaRecorder, ReactMediaRecorder } from "react-media-recorder";
import Container from '../components/Container';
import { Box, Heading, Text } from '@chakra-ui/layout';
import { pageWidth } from '../theme/styles';
import colors from '../theme/colors';
import { Button, Modal, ModalContent, ModalCloseButton, ModalBody, ModalOverlay, useDisclosure, Textarea } from '@chakra-ui/react';
import ProfileImageAndPInfo from '../components/ProfileImageAndPInfo';
import { BiPlus, RiRecordCircleLine, IoStopCircleOutline, BsDownload, ImCancelCircle } from 'react-icons/all';
import { Link as RLink, useHistory, Redirect } from 'react-router-dom';
import { auth, db } from "../firebase/base";
import { createUserWithEmailAndPassword } from "firebase/auth"
import { collection, doc, setDoc } from "firebase/firestore";
import { useAlert } from "react-alert"
import { AuthContext } from "../firebase/Auth"
import { UploadFile } from "../firebase/UploadFile"
import SpeechToText from "../components/SpeechToText/speechToText"
import routes from "../constants/routes";
const SignUp2 = ({ location }) => {
  //UploadVideo
  const UploadVideo = (props) => {
    const {
      title = '',
      text = '',
    } = props;
    return (
      <Box
        d="flex"
        flexDirection="column"
        w={{ md: "37.5vw" }}
        mt={{ base: "20px", md: "0" }}
        {...props}
      >
        {/* Upload(ed) video area */}
        <label for="upload">
          <Box
            onClick={() => { onOpen(); setOneTitle(title) }}
            d="flex"
            w="100%"
            h="16.805555556vw"
            minH="240px"
            background="linear-gradient(180deg, #21273E 0%, #2B334B 100%)"
            borderRadius="10px"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
          >
            <BiPlus size={52} color={colors.secondary}  > </BiPlus>
          </Box>
          {/* <input type="file" id="upload" style={{ display: "none" }} onChange={handleChange} /> */}
        </label>


        {/* Info area */}
        <Box
          d="flex"
          flexDirection="column"
          w="100%"
          mt="15px"
          style={{ gap: 5 }}
        >
          <Heading as="h4" size="lg" color="#fff">
            {title}
          </Heading>
          <Text fontSize="xl" color="#fff">
            {text}
          </Text>
        </Box>
      </Box>
    );
  };

  //UploadVideo
  const alert = useAlert()
  const [data, setData] = useState(location.state ? location.state.data : "")
  const [file, setFile] = useState([]);
  const [oneFile, setOneFile] = useState();
  const [oneSubtitle, setSubtitle] = useState();
  const [subtitles, setSubtitles] = useState([]);
  const [videoTitle, setTitle] = useState([]);
  const [oneVideoTitle, setOneTitle] = useState();
  const [visable, setVisable] = useState(false);
  const [visable2, setVisable2] = useState(false);
  const [visable3, setVisable3] = useState(false);
  const [videoBox, setVideoBox] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory();
  const { currentUser } = useContext(AuthContext)
  useEffect(() => {

  }, [visable3])

  if (!location.state || currentUser) {
    history.push(routes.signUpRecruiter)
  }
  const handleLogin = async () => {
    try {
      if (file.length !== 0) {
        UploadFile(file, "video", createUser, alert)
        alert.info("You will be notified when the download is complete")
        console.log("upload")
      }
      else {
        try {
          const fileref = collection(db, 'users')
          await setDoc(doc(fileref, data.email), data)
          await createUserWithEmailAndPassword(auth, data.email, data.password)
          alert.success("Registered successful, you are redirected")
          history.push(routes.search);
        } catch (error) {
          console.log(error)
          alert.error(error.code)
        }
      }
    } catch (error) {
      alert.error(error.code)
    }
  }
  let fileLinks = []
  const createUser = (url) => {
    const fileref = collection(db, 'users')
    fileLinks.push(url)
    if (file.length == fileLinks.length) {
      data.videoTitle = videoTitle
      data.subtitles = subtitles
      data.videos = fileLinks
      setTimeout(async () => {
        try {
          await setDoc(doc(fileref, data.email), data)
          await createUserWithEmailAndPassword(auth, data.email, data.password)
          alert.success("Registered successful, you are redirected")
          history.push(routes.search);
        } catch (error) {
          console.log(error)
          alert.error(error.code)
        }

      }, 2000);
    }
  }

  const uploadFiles = () => {
    if (oneFile && oneSubtitle && oneVideoTitle) {
      setFile([...file, oneFile]);
      setSubtitles([...subtitles, oneSubtitle]);
      setTitle([...videoTitle, oneVideoTitle]);
      setSubtitle(null)
      setOneFile(null)
      alert.success("Successful")
    }
  }

  const handleChange = async e => {
    if (e.target.files[0]) {
      if (e.target.files[0]) {
        if (e.target.files[0].size > 20000000) {
          alert.error("File Size Too Large!");
          e.target.value = "";
        }
        else {
          if (e.target.files[0].type == "video/mp4") {
            setOneFile(e.target.files[0])
          }
          else {
            alert.error("Invalid File Format.")
            e.target.value = "";
          }
        }
      }
    }
  };

  const setSubtitleFile = async (e) => {
    e.preventDefault()
    if (e.target.files[0].type == "text/plain") {
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target.result)
        setSubtitle(text);
      };
      reader.readAsText(e.target.files[0])
    }
    else {
      alert.error("Invalid File Format.")
      e.target.value = "";
    }
  }

  const callback = (key, value, keys) => {
    let i = true
    keys && keys.length !== 0 && keys.map(x => {

      if (x.key == "instagram") {
        data.instagram = x.value
      }
      if (x.key == "linkedin") {
        data.linkedin = x.value
      }
      if (x.key == "telegram") {
        data.telegram = x.value
      }
      if (x.key == "twitter") {
        data.twitter = x.value
      }
      if (x.key == "facebook") {
        data.facebook = x.value
      }
      if (i) {
        alert.success("Successful")
      }
      i = false
    })

    if (key == "firstname") {
      data.first = value
    }
    if (key == "lastname") {
      data.last = value
    }
    if (key == "state") {
      data.state = value
    }
    if (key == "city") {
      data.city = value
    }
    if (key == "website") {
      data.website = value
    }
  }

  const handleProfileAvatar = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) {
        alert.error("File Size Too Large!");
        e.target.value = "";
      }
      else {
        if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == 'image/png') {
          var reader = new FileReader();
          // Read in the image file as a data URL.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (evt) {
            if (evt.target.readyState == FileReader.DONE) {
              var output = document.getElementById('pp');
              output.style.background = `url(${evt.target.result})`
            }
          }

          UploadFile([e.target.files[0]], "avatar", uploadAvatar, alert)
          alert.info("You will be notified when the download is complete")
          console.log("upload")
        }
        else {
          alert.error("Invalid File Format.")
        }
      }
    }
  }
  const uploadAvatar = (url, fileLinks) => {
    data.avatar = url
    alert.success("Success")
  }

  const VideoPreview = ({ stream }) => {
    const videoRef = useRef(null);
    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);
    if (!stream) {
      return null;
    }

    return (
      <Fragment>
        <Box id="videoBox">
          <video style={{ borderRadius: "5px", margin: "auto" }} ref={videoRef} width={600} height={600} autoPlay />
          <SpeechToText status={true} />
        </Box>
      </Fragment>
    )
  };

  const printSubtitle = () => {
    let clk = document.getElementById("speechBox")
    if (clk) {
      setTimeout(() => {
        clk.click()
      }, 10000);
    }
  }

  const RecordView = () => {
    const {
      status,
      startRecording,
      stopRecording,
      mediaBlobUrl,
      previewStream
    } = useReactMediaRecorder({ video: true });

    
    return (
      <div>
        <Button disabled={status == "recording" ? true : false} _hover="none" background="transparent" color={colors.secondary} onClick={() => {
          startRecording()
          document.getElementById("backarrow1").classList.add("disabledbutton")
          document.getElementById("closebtn1").classList.add("disabledbutton")
        }}><RiRecordCircleLine style={{ marginRight: "5px" }} size={32} color={colors.secondary} /> Start Recording </Button>
        <Button disabled={status == "recording" ? false : true} _hover="none" background="transparent" color={colors.secondary} onClick={() => {
          printSubtitle();
          document.getElementById("videoBox").style.display = "none";
          document.getElementById("videoText").style.display = "block";
          document.getElementById("cancelbtn").style.display = "inline-flex";
          alert.info("PROCESSING PLEASE WAIT")
          stopRecording();
          setTimeout(() => {
            document.getElementById("downloadLink").classList.remove("disabledbutton")
            document.getElementById("backarrow1").classList.remove("disabledbutton")
            document.getElementById("closebtn1").classList.remove("disabledbutton")
            document.getElementById("cancelbtn").classList.remove("disabledbutton")
            document.getElementById("videoText").style.display = "none";
            return (<SpeechToText status={false} />)
          }, 10200);
        }}><IoStopCircleOutline style={{ marginRight: "5px" }} size={32} color={colors.secondary} />Stop Recording</Button>

        {/* <Button _hover="none" background="transparent" color={colors.secondary} >{status}</Button> */}
        <a className="disabledbutton" id="downloadLink" onClick={downloadSubtitleFile} href={mediaBlobUrl} download><Button _hover="none" background="transparent" color={colors.secondary}  ><BsDownload style={{ marginRight: "8px" }} size={25} color={colors.secondary} />Download</Button></a>
        <Button className="disabledbutton" id="cancelbtn" display="none" _hover="none" background="transparent" color={colors.secondary} onClick={() => { setVisable3(true) }}><ImCancelCircle style={{ marginRight: "8px" }} size={25} color={colors.secondary} />Cancel</Button>
        <Box flexDirection="column" d="flex" margin="auto" mt="30px">
          <Text display="none" id="videoText" mx="20px" color="white">PROCESSING PLEASE WAIT</Text>
          {mediaBlobUrl &&
            <Fragment>
              <video style={{ borderRadius: "5px", margin: "auto" }} src={mediaBlobUrl} width={600} height={600} controls autoplay />
            </Fragment>
          }
          <VideoPreview stream={previewStream} />
        </Box>
        {visable3 && <Fragment>
          <SpeechToText status={false} />
          {setVisable3(false)}
        </Fragment>}
      </div >
    );
  };
  const downloadSubtitleFile = () => {
    const element = document.createElement("a");
    const filedata = sessionStorage.getItem("subtitle")
    const file = new Blob([filedata], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "Subtitle.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }



  return (
    <Container>
      {/* Profile image and profile info area */}

      <ProfileImageAndPInfo setProfileAvatar={handleProfileAvatar} socialMedia={callback} currentUser={data} />
      {/* Upload(ed) video(s) rows */}
      <Box
        d="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={colors.pageBG}
      >

        {data.userType == "talent" ?
          <Box
            d="flex"
            flexDirection="column"
            w="100%"
            maxW={pageWidth}
          >
            {/* Upload(ed) video(s) row */}
            <Box
              d="flex"
              justifyContent="space-between"
              w="100%"
              borderBottom="1px solid #8D93A8"
              paddingBottom="48px"
              marginBottom="60px"
              flexDirection={{ base: "column", md: "row" }}
            >
              <UploadVideo
                title="Introductory Video"
                text="Uploading an Introduction Video increases your chances of getting hired by 250%. Introdcue yourself breifly & stand out"
              />
              <UploadVideo
                title="Expertise Video"
                text={(
                  <>
                    Tell us 2-3 of your most important skills, your specialties & your expertise! {" "}
                    <span style={{ color: colors.secondary }}>20MB Max File Limit</span>
                  </>
                )}

              />
            </Box>

            {/* Upload(ed) video(s) row */}
            <Box
              d="flex"
              justifyContent="space-between"
              w="100%"
              borderBottom="1px solid #8D93A8"
              paddingBottom="48px"
              marginBottom="60px"
              flexDirection={{ base: "column", md: "row" }}
            >
              <UploadVideo
                title="Long Term Goals"
                text="Tell the employer what your long term goals are to match with an ideal job for you! "
              />
              <UploadVideo
                title=" Describe your ideal job?"
                text="Describe your ideal job, the team, company culture, day to day responsibilities or anything that matters to you! "
              />
            </Box>
            {/* Upload(ed) video(s) row */}
            <Box
              d="flex"
              justifyContent="space-between"
              w="100%"
              borderBottom="1px solid #8D93A8"
              paddingBottom="48px"
              marginBottom="60px"
              flexDirection={{ base: "column", md: "row" }}
            >
              <UploadVideo
                title="What do your friends say about you?"
                text="How would your freinds and family describe you? what are the personality traits you are mostly known for?"
              />
              <UploadVideo
                title="Anything else we should know?"
                text="Tell the emoloyer something that would set you apart from other applicants!  "
              />
            </Box>

          </Box>

          :

          <Box
            d="flex"
            flexDirection="column"
            w="100%"
            maxW={pageWidth}
          >
            {/* Upload(ed) video(s) row */}
            <Box
              d="flex"
              justifyContent="space-between"
              w="100%"
              borderBottom="1px solid #8D93A8"
              paddingBottom="48px"
              marginBottom="60px"
              flexDirection={{ base: "column", md: "row" }}
            >
              <UploadVideo
                title="Who Are We?"
                text="Uploading an Introduction Video increases your chances of getting viewed by 250%"
              />
              <UploadVideo
                title="Who Do We Hire?"
                text="Explain who is an ideal candidate that will match your organization best! "

              />
            </Box>

            {/* Upload(ed) video(s) row */}
            <Box
              d="flex"
              justifyContent="space-between"
              w="100%"
              borderBottom="1px solid #8D93A8"
              paddingBottom="48px"
              marginBottom="60px"
              flexDirection={{ base: "column", md: "row" }}
            >
              <UploadVideo
                title="Why You Should Work For Us?"
                text="What are some of the perks of working with your organization!  "
              />
              <UploadVideo
                title="Company Culture  "
                text=" Explain your company culture or anything else you would like to share! "
              />
            </Box>
          </Box>
        }

      </Box>

      {/* "FINISH" button */}
      <Box
        d="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={colors.pageBG}
        pb="100px"
      >
        <Button
          onClick={() => handleLogin()}
          size="lg"
          bgColor={colors.secondary}
          color={colors.primary}
          _hover={{
            bg: colors.secondary
          }}
          fontWeight="500"
          d="flex"
          justifyContent="center"
          alignItems="center"
          w="187px"
          h="61"
        >
          FINISH
        </Button>
      </Box>
      {/* Upload file Modal */}
      <Modal onClose={onClose} size={"3xl"} isOpen={isOpen} closeOnOverlayClick={false} >
        <ModalOverlay zIndex="1" />
        <ModalContent background={colors.linearBG}>
          <ModalCloseButton id="closebtn1" color="white" onClick={() => setVisable3(true)} />
          {!visable ?
            <ModalBody flexDirection="column" p="50px" d="flex">
              <Box
                onClick={() => { setVisable(true); setVisable2(false) }}
                d="flex"
                w="100%"
                h="16.805555556vw"
                minH="240px"
                background={colors.pageBG}
                borderRadius="10px"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                mb="30px"
              >
                <RiRecordCircleLine size={52} color={colors.secondary}  > </RiRecordCircleLine>
                <Text mt="10px" color={colors.secondary}>Record Video</Text>
              </Box>
              <Box d="flex">
                <label style={{ marginRight: "20px" }} for="upload">
                  <Box
                    d="flex"
                    w="100%"
                    minH="130px"
                    background={colors.pageBG}
                    borderRadius="10px"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    p="20px"
                  >
                    <BiPlus size={52} color={colors.secondary}  > </BiPlus>
                    <Text mt="10px" color={colors.secondary}>Choose Video File</Text>
                  </Box>
                  <input type="file" id="upload" style={{ display: "none" }} onChange={(e) => handleChange(e)} />
                </label>

                <label for="uploadSubtitle">
                  <Box
                    d="flex"
                    w="100%"
                    minH="130px"
                    background={colors.pageBG}
                    borderRadius="10px"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    p="20px"
                  >
                    <BiPlus size={52} color={colors.secondary}  > </BiPlus>
                    <Text mt="10px" color={colors.secondary}>Choose Subtitle File</Text>
                  </Box>
                  <input type="file" id="uploadSubtitle" style={{ display: "none" }} onChange={(e) => setSubtitleFile(e)} />
                </label>

                <Button disabled={oneFile ? false : true} ml="20px" background={colors.secondary} onClick={() => { uploadFiles(); onClose() }}>Upload</Button>
              </Box>
            </ModalBody>
            :
            <ModalBody flexDirection="column" p="50px" d="flex">
              <Box
                d="flex"
                flexDirection="row"
                alignItems="baseline"
                mb="24px"
              >
                <Box
                  id="backarrow1"
                  onClick={() => { setVisable3(true); setVisable(false) }}
                  fontSize="16px"
                  fontWeight="bold"
                  textTransform="uppercase"
                  letterSpacing="2px"
                  color={colors.secondary}
                  cursor="pointer"
                >
                  &#8592;    BACK
                </Box>
              </Box>

              <RecordView />

            </ModalBody>
          }
          {visable2 &&
            <ModalBody flexDirection="row" p="50px" d="flex">

              <label style={{ marginRight: "20px" }} for="upload">
                <Box
                  d="flex"
                  w="100%"
                  minH="130px"
                  background={colors.pageBG}
                  borderRadius="10px"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  p="20px"
                >
                  <BiPlus size={52} color={colors.secondary}  > </BiPlus>
                  <Text mt="10px" color={colors.secondary}>Choose Video File</Text>
                </Box>
                <input type="file" id="upload" style={{ display: "none" }} onChange={(e) => handleChange(e)} />
              </label>

              <label for="uploadSubtitle">
                <Box
                  d="flex"
                  w="100%"
                  minH="130px"
                  background={colors.pageBG}
                  borderRadius="10px"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  p="20px"
                >
                  <BiPlus size={52} color={colors.secondary}  > </BiPlus>
                  <Text mt="10px" color={colors.secondary}>Choose Subtitle File</Text>
                </Box>
                <input type="file" id="uploadSubtitle" style={{ display: "none" }} onChange={(e) => setSubtitleFile(e)} />
              </label>

              <Button disabled={oneFile ? false : true} ml="20px" background={colors.secondary} onClick={() => { uploadFiles(); onClose() }}>Upload</Button>
            </ModalBody>}
        </ModalContent>
      </Modal >
      {/* Upload file Modal */}

      {/* Upload Record Video Modal */}

    </Container >
  )
}

export default SignUp2
