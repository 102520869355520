import Container from "../components/Container";
import { Box, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Textarea, Checkbox, NumberInput, NumberInputField, NumberDecrementStepper, NumberIncrementStepper, NumberInputStepper } from "@chakra-ui/react"
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import React, { useState, useRef, useContext } from 'react';
import { auth, db } from "../firebase/base";
import { collection, doc, setDoc } from "firebase/firestore";
import { AuthContext } from "../firebase/Auth"
import colors from '../theme/colors';
import { fontSizes, pageWidth } from '../theme/styles';
import { useAlert } from "react-alert";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import Validate from "../components/Validate"
import { State } from "../lib/lib";
const PostJob = ({ history }) => {
    const { currentUser } = useContext(AuthContext)

    const [title, setTitle] = useState("");
    const [salary, setSalary] = useState();
    const [company, setCompany] = useState(currentUser.company);
    const [employment, setEmployment] = useState("");
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState({})
    const [tags, setTags] = useState([]);
    const [remote, setRemote] = useState(false)
    const [suggestions, setSuggestions] = useState([]);


    const alert = useAlert()
    const handleTags = (tag) => {
        setTags(tag)
    }
    const data = {
        title,
        salary,
        company,
        employment,
        location,
        description,
        tags,
        registerUser: [""],
        remote,
        owner: currentUser.email
    }

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const createJob = async () => {
        const fileref = collection(db, 'jobs')
        const errors = valid()
        setError(errors);
        if (Object.keys(errors).length === 0) {
            try {
                let id = uuidv4()
                data.salary = parseInt(salary)
                await setDoc(doc(fileref, id), data)
                alert.success("Upload successful, you are redirected")
                history.push(`/details/${id}`)
            } catch (error) {
                alert.error(error.code)
            }
        }
    }
    const valid = () => {
        const errors = {};
        if (!title) errors.title = "Job title field is required.";
        if (!salary) errors.salary = "Salary field is required.";
        if (!location) errors.location = "Location field is required.";
        if (!description) errors.description = "Description field is required.";
        return errors;
    }
    const onchangeKeyword = (e) => {
        if (e.search(/[\[\]?*+'`"|{}\\()@!:.\n\r]/) == -1) {
            setSalary(e)
        }
    }
    
    const errors = { ...error };

    return (
        <Container>
            <Box
                alignItems="center"
                d="flex"
                backgroundColor={colors.pageBG}
                pt="100px"
            >
                <Box
                    alignItems="center"
                    maxW={pageWidth}
                    margin="auto"
                    width="100%"

                >
                    <Box
                        d="flex"
                        flexDirection="row"
                        alignItems="baseline"
                    >
                        <Text fontSize="48" fontWeight="bold" color="#fff">Post a New Job</Text>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="baseline"
                        mt="64px"
                    >
                        <Box
                            d="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="baseline"
                            width="100%"
                            mr="65px"
                        >
                            <FormControl mr="5">
                                <Formlabel>Job Title</Formlabel>
                                <FormInput placeholder="Enter text here" type="text" onChange={(e) => setTitle(e.target.value)} />
                                {errors.title && <Validate message={errors.title} />}
                            </FormControl>

                            <FormControl mr="5">
                                <Formlabel>Salary</Formlabel>
                                {/* <NumberInput clampValueOnBlur={false} placeholder="Enter text here">
                                    <NumberInputField value={salary} onChange={(e) => onchangeKeyword(e.target.value)} focusBorderColor="none" height="72px" pl="20px" mb="24px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} placeholder="Enter text here" />
                                </NumberInput> */}
                                 <FormInput  placeholder="Enter text here" type="text" value={salary} onChange={(e) => onchangeKeyword(e.target.value)} />
                                {errors.salary && <Validate message={errors.salary} />}
                            </FormControl>

                            <FormControl mr="5">
                                <Formlabel>Most Important Skills</Formlabel>
                                <TagsInput value={tags} onChange={handleTags} />
                            </FormControl>

                            <FormControl mr="5">
                                <Formlabel>Employment Type</Formlabel>
                                <Select onChange={(e) => setEmployment(e.target.value)} height="72px" placeholder="Select an Employment Type" mb="24px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)", paddingLeft: "20px" }}>
                                    <option>Full Time</option>
                                    <option>Part Time</option>
                                    <option>Temporary</option>
                                    <option>Contractor</option>
                                    <option>Remote</option>
                                </Select>
                            </FormControl>

                            <FormControl mr="5">
                                <Formlabel>Location</Formlabel>
                                <Select onChange={(e) => setLocation(e.target.value)} name="city" height="72px" placeholder="Select an Location" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)", paddingLeft: "20px" }}>
                                    {State.map(x =>
                                        <option value={x}>{x}</option>
                                    )}
                                </Select>
                                {errors.location && <Validate message={errors.location} />}
                            </FormControl>
                        </Box>
                        <Box
                            alignItems="baseline"
                            width="100%"
                        >
                            <FormControl >
                                <Formlabel>Job Description</Formlabel>
                                <Textarea onChange={(e) => setDescription(e.target.value)} pt="25px" focusBorderColor="none" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)", height: "650px" }} placeholder=" Job Description" />
                                {errors.description && <Validate message={errors.description} />}
                            </FormControl>
                            <Box d="flex" justifyContent="flex-end" mt="44px" alignItems="center">
                                <Text d="flex" color="#fff" >Remote <Checkbox outline="none" pl="30px" style={{ display: "inline-block" }} defaultChecked={remote} onChange={() => setRemote(!remote)} /> </Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        mt="161px"
                        d="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Text fontSize={fontSizes.normal} color="#fff">By Posting a Job, you agree to our <b>Terms and Conditions</b> and<br /><b> Privacy Policy</b></Text>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        pb="90px"
                    >
                        <Button
                            onClick={() => createJob()}
                            size="lg"
                            bgColor={colors.secondary}
                            color={colors.primary}
                            _hover={{
                                bg: colors.secondary
                            }}
                            fontWeight="500"
                            d="flex"
                            justifyContent="center"
                            alignItems="center"
                            w="187px"
                            h="61"
                            mt="28px"
                        >
                            POST JOB
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>

    )
}
const FormInput = ({ children, ...props }) => {
    return (
        <Input  {...props} focusBorderColor="none" height="72px" pl="20px" mb="24px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}>
            {children}
        </Input>
    );
};
const Formlabel = ({ children, ...props }) => {
    return (
        <FormLabel {...props} color="#fff" mb="24px" fontWeight="600" >
            {children}
        </FormLabel>
    );
};
export default PostJob