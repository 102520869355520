import React from 'react';
import { Link as CLink } from '@chakra-ui/layout';
import { Link as RLink } from 'react-router-dom';

const NavbarLink = ({ to = '', children = '', ...props }) => {
  return (
    <CLink
      color="#fff"
      as={RLink}
      to={to}
      fontWeight="600"
      paddingX={'4'}
      textTransform="uppercase"
      {...props}
    >
      {children}
    </CLink>
  );
};

export default NavbarLink;
