export const filter = (data, userData, query) => {
    let filterdata = {}
    if (query.title == "") {
        filterdata = data;
    }
    if (data) {
        filterdata = data.filter((data) => {
            const filter = data.title.toLowerCase();
            return filter.includes(query.title.toLowerCase());
        })
    }
    if (userData) {
        filterdata = userData.filter((data) => {
            const filter = data.first.toLowerCase();
            return filter.includes(query.title.toLowerCase());
        })
    }

    if (query.remote == true) {

        filterdata = filterdata.filter((data) => {
            const filter = data.remote
            return filter == true
        })

    }
    if (query.location) {
        filterdata = filterdata.filter((data) => {
            const filter = data.location.toLowerCase()
            return filter.includes(query.location.toLowerCase());
        })

    }
    if (query.employment) {
        filterdata = filterdata.filter((data) => {
            const filter = data.employment.toLowerCase()
            return filter.includes(query.employment.toLowerCase());
        })

    }
    
    if (query.priceMin >= 0) {
        const minPrice = filterdata.filter((data) => {
            return data.salary >= query.priceMin
        })
        filterdata = minPrice.filter((data) => {
            return data.salary <= query.priceMax
        })

    }

    return filterdata
};