
import React from 'react';
import colors from '../../theme/colors';
import { pageWidth } from '../../theme/styles';
import Logo from '../Logo';
import NavbarLink from './NavbarLink';
import { signOut } from "firebase/auth"
import { auth } from "../../firebase/base";
import routes from "../../constants/routes"
import { Link } from "react-router-dom"
import { Box, Flex, Text, Button, Stack } from "@chakra-ui/react";
const Navbar = ({ height = '150px', history }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Box
      backgroundColor={colors.primary}
      height={height}
      pt="20px"
      px={{ base: "30px", md: "0px" }}
    >
      <Box
        d="flex"
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
        maxW={pageWidth}
        margin="auto"
        alignItems={{ base: "unset", md: "center" }}
      >
        <Logo />
        <Box
          d="flex"
          flexDirection="row"
          justifyContent="center"
          py={{ base: "20px" }}
          borderRadius="10px"
          zIndex="1"
          background={{ base: colors.primary, md: "none" }}
        >
          <MenuLinks isOpen={isOpen} />
          <MenuToggle toggle={toggle} isOpen={isOpen} />
          {/* <NavbarLink to="/">Home</NavbarLink>
          <NavbarLink to="/search">Search</NavbarLink>
          <NavbarLink>About </NavbarLink>
          <Button width="90px" as={Link} to={routes.signUpTalent} bgColor={colors.secondary} color={colors.text} mx="4" ml="10">
            Sign Up
          </Button>
          <Button width="90px" as={Link} to={routes.signInTalent} bgColor={colors.secondary} color={colors.text}  >
            Sign In
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};


const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      <MenuIcon />
    </Box>
  );
};


const MenuLinks = ({ isOpen }) => {

  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      p={{ base: "20px" }}
    >
      <Stack
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "column", "row", "row"]}
        pt={[4, 4, 0, 0]}

      >
        <NavbarLink to="/">Home</NavbarLink>
        <NavbarLink to="/search">Search</NavbarLink>
        <NavbarLink to="/aboutus">About Us </NavbarLink>
        <Button width="90px" as={Link} to={routes.signUpTalent} bgColor={colors.secondary} color={colors.text} >
          Sign Up
        </Button>
        <Button width="90px" as={Link} to={routes.signInTalent} bgColor={colors.secondary} color={colors.text} >
          Sign In
        </Button>
      </Stack>
    </Box>
  );
};
export default Navbar;
