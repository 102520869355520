import React from 'react';
import { Box, Text } from '@chakra-ui/layout';
import Container from '../components/Container';
import SignUpCard from '../components/landing/SignUpCard';
import { fontSizes, pageWidth } from '../theme/styles';
import Building from '../assets/icons/Building';
import Person from '../assets/icons/Person';
import colors from '../theme/colors';
import { Image } from '@chakra-ui/image';
import { Button, IconButton } from '@chakra-ui/button';
import image2 from '../assets/images/loadingimage2.png';
import image1 from '../assets/images/landing-pic1.png';
import { SearchIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import routes from '../constants/routes';
import { Redirect } from 'react-router';
import video4 from "../assets/video/video4.mp4"
const Landing = ({ history }) => {
  window.scrollTo(0, 0)
  return (
    <Container>
      <Box
        height={{ base: '36', md: '20' }}
        backgroundColor={colors.primary}
      ></Box>
      <Box
        display="flex"
        alignItems="center"
        bgColor="#21273E"
        w={{ base: '90%', md: '80%' }}
        alignSelf="center"
        p="10"
        margin="auto"
        borderRadius="10"
        position="absolute"
        marginTop={{ base: '-36', md: '-20' }}
        left={{ base: '5%', md: '10%' }}
        flexDirection={{ base: 'column', xl: 'row' }}
      >
        <Box flex="2" mb={{ sm: "10px", xl: "0px" }} w={{ base: '100%', md: undefined }}>
          <Text lineHeight="22px" mb="10px" color="#fff" fontWeight="600" fontSize={fontSizes.medium}>
            AI-Powered & Digital <br />
            Work Marketplace
          </Text>
          <Text lineHeight="22px" color="#fff" fontSize={fontSizes.normal}>
            Cut Your Time-To-Hire<br />
            Get Discovered
          </Text>
        </Box>
        <Box
          w={{ base: '100%', md: undefined }}
          display="flex"
          alignItems="center"
          backgroundColor="#fff"
          px="10"
          py="5"
          borderRadius="10"
          fontSize={fontSizes.normal}
          marginX="3"
          mt={{ base: '3', md: 0 }}
          flex="3"
        >
          <Input
            variant="unstyled"
            placeholder="Search for Jobs or Talent"
            size="lg"
          />
          <IconButton
            onClick={() => history.push(routes.search)}
            variant="unstyled"
            aria-label="Search"
            size="lg"
            icon={<SearchIcon />}
          />
        </Box>
      </Box>
      <Box
        color="#fff"
        p={{ base: '10', md: '16', xl: '32' }}
        background={video4}
        display="flex"
        alignItems="baseline"
        justifyContent="space-around"
        flexDirection={{ base: 'column', xl: 'row' }}
        pt={{ base: '44', md: '60' }}
      >
        <video id="background-video" src={video4} autoPlay muted  > </video>
        <Box flex="2" justifyContent="flex-end" lineHeight={{ md: "50px" }} maxW="500px" ml="50px">
          <Text mb="24px" fontWeight="bold" as="h1" fontSize={[fontSizes.normal, fontSizes.medium, fontSizes.h1]}>
            Sign up now to start looking for jobs or talent
          </Text>
          <Text fontSize={fontSizes.normal} lineHeight="22px">
            Recruitment services to help you start your career with ease
          </Text>
        </Box>
        <Box
          margin={{ base: '2', md: '10' }}
          flex="1"
          display="flex"
          justifyContent="flex-end"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <SignUpCard
            buttonBg={colors.pageBG}
            title="Find Your Ideal Employee "
            btnTitle="SIGN UP AS EMPLOYERS"
            desc="Search for talent & discover the  candidate behind a resume who will fit your company culture with matching skills! Learn more about each individual as they will have an opportunity to share more about their goals, expertise, passions & much more! "
            icon={<Building />}
            onClick={() => history.push(routes.signUpRecruiter)}
          />
          <SignUpCard
            buttonBg={'#59ACA7'}
            title="Find Your Ideal Job"
            btnTitle="SIGN UP AS TALENT"
            desc="Get Discovered by posting about your experience, goals, passions & your ideal job! let your voice be heard & show the employers who is behind your resume! We are more than just a resume! Let’s get the Dialogge started! "
            icon={<Person />}
            onClick={() => history.push(routes.signUpTalent)}
          />
        </Box>
      </Box>
      <Box
        alignItems="center"
        d="flex"
        backgroundColor={'#293048'}
        pt="96px"
        pb="100px"
      >
        <Box
          alignItems="center"
          maxW={pageWidth}
          margin="auto"
          width="100%"
          d="flex"
          justifyContent="center"
          flexDirection={{ base: 'column', xl: 'row' }}
        >
          <Box ml="44px">
            <Text color="white" lineHeight="50px" fontSize="32px" fontWeight="bold" maxWidth="560px" width="100%" >
              Employers on Average spend only 6 seconds on each resume. Dialogge allows us to discover much more about who is behind every resume!
              Experience our AI-Powered Technolgy & Digital Features to discover talent the NEW way.
            </Text>
            {/* <PText mt="24px" maxWidth="415px" width="100%">
              Recruitment services to help you start your career with ease
            </PText> */}
            <Button
              bgColor={colors.secondary}
              color={colors.primary}
              px="30px"
              py="23px"
              mt="48px"
              onClick={() => history.push(routes.postjob)}
            >
              POST A JOB
            </Button>
          </Box>
          <Box marginY={{ base: '5', xl: '0' }}>
            <Image src={image1} height="451px" width="497px" padding={{ base: "20px" }} />
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="center"
        d="flex"
        backgroundColor={'#323A51'}
        pt="96px"
        pb="100px"
      >
        <Box
          alignItems="center"
          maxW={pageWidth}
          margin="auto"
          width="100%"
          d="flex"
          justifyContent="center"
          flexDirection={{ base: 'column-reverse', xl: 'row' }}
        >
          <Box marginY={{ base: '5', xl: '0' }}>
            <Image src={image2} height="451px" width="497px" padding={{ base: "20px" }} />
          </Box>
          <Box ml="44px">
            <Text color="white" lineHeight="50px" fontSize="32px" fontWeight="bold" maxWidth="560px" width="100%">
              Ever thought if only you had
              the chance to get in front of the
              employer, you would get your
              dream job? Here is a chance to
              show the employers who is
              behind your resume. Dont get
              overlooked, GET DISCOVERED!
            </Text>
            {/* <PText mt="24px" maxWidth="415px" width="100%">
              Recruitment services to help you start your career with ease
            </PText> */}
            <Button
              bgColor={colors.secondary}
              color={colors.primary}
              px="30px"
              py="23px"
              mt="48px"
              onClick={() => history.push(routes.signUpTalent)}
            >
              START THE DIALOGGE
            </Button>
          </Box>
        </Box>
      </Box>
    </Container >
  );
};

const HeaderText = ({ children, ...props }) => {
  return (
    <Text
      {...props}
      fontSize="48px"
      fontWeight="bold"
      lineHeight="50px"
      color="#fff"
    >
      {children}
    </Text>
  );
};

const PText = ({ children, ...props }) => {
  return (
    <Text {...props} fontSize="18px" lineHeight="22px" color="#fff">
      {children}
    </Text>
  );
};

export default Landing;
