export const pageWidth = {
  md: '80%',
  base:"80%"
};

export const fontSizes = {
  small: 12,
  text: 14,
  normal: 18,
  medium: 24,
  h1: 48,
};
