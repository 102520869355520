import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAxDhkrOh4qPWBjzNsp-iv_FRdOP1AKZhM",
  authDomain: "recruiting-1.firebaseapp.com",
  projectId: "recruiting-1",
  storageBucket: "recruiting-1.appspot.com",
  messagingSenderId: "134560296691",
  appId: "1:134560296691:web:ad4c1a51629e0b2be5d186",
  measurementId: "G-33W8VRFMYM"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore();
export const auth = getAuth();
export default app
