const routes = {
    landing: '/',
    signUpRecruiter: '/sign-up-employer',
    signUp2: '/sign-up-2',
    signInRecruiter: '/sign-in-employer',
    signUpTalent: '/sign-up-talent',
    signInTalent: '/sign-in-talent',
    landing2: '/aboutus',
    jobdetails: '/details/:title',
    postjob: '/postjob',
    profile: '/profile/:id',
    search: '/search'
}


export default routes;