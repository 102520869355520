import * as React from 'react';

function Building({ fill = '#323A51', ...props }) {
  return (
    <svg
      width={34}
      height={42}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.653 39.05H29.96V6.06c0-.744-.602-1.347-1.346-1.347h-10.1V1.347C18.515.603 17.913 0 17.169 0H7.827C7.083 0 6.48.603 6.48 1.347v3.366H5.386c-.743 0-1.346.603-1.346 1.346v32.99H1.347a1.347 1.347 0 000 2.694h31.306a1.347 1.347 0 100-2.694zM9.173 2.693h6.649v2.02H9.173v-2.02zm5.134 36.357v-5.723h5.386v5.723h-5.386zm8.08 0v-5.723h.588a1.347 1.347 0 100-2.693h-11.95a1.347 1.347 0 000 2.693h.589v5.723H6.733V7.406h20.534V39.05h-4.88zM15.063 11.36c0 .744-.603 1.347-1.346 1.347h-1.515a1.347 1.347 0 110-2.693h1.515c.743 0 1.346.603 1.346 1.346zm7.995 0c0 .744-.602 1.347-1.346 1.347h-1.515a1.347 1.347 0 010-2.693h1.515c.744 0 1.346.603 1.346 1.346zm-7.995 5.05c0 .743-.603 1.346-1.346 1.346h-1.515a1.347 1.347 0 110-2.693h1.515c.743 0 1.346.603 1.346 1.347zm7.995 0c0 .743-.602 1.346-1.346 1.346h-1.515a1.347 1.347 0 010-2.693h1.515c.744 0 1.346.603 1.346 1.347zm-7.995 5.05c0 .743-.603 1.346-1.346 1.346h-1.515a1.347 1.347 0 110-2.693h1.515c.743 0 1.346.603 1.346 1.346zm7.995 0c0 .743-.602 1.346-1.346 1.346h-1.515a1.347 1.347 0 010-2.693h1.515c.744 0 1.346.603 1.346 1.346zm-7.995 5.049c0 .743-.603 1.346-1.346 1.346h-1.515a1.347 1.347 0 110-2.693h1.515c.743 0 1.346.603 1.346 1.347zm7.995 0c0 .743-.602 1.346-1.346 1.346h-1.515a1.347 1.347 0 010-2.693h1.515c.744 0 1.346.603 1.346 1.347z"
        fill={fill}
      />
    </svg>
  );
}

export default Building;
