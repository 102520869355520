import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import PrivateRoute from "./constants/PrivteRoute";
import { AuthProvider } from "./firebase/Auth";

import routes from './constants/routes';
import Landing from './screens/Landing';
import Landing2 from './screens/Landing2';
import SignUpTalent from './screens/SignUpTalent';
import SignUpRecruiter from './screens/SignUpRecruiter';
import SignInRecruiter from './screens/SignInRecruiter';
import SignInTalent from './screens/SignInTalent';
import SignUp2 from './screens/SignUp2';
import Jobdetails from './screens/JobDetails';
import PostJob from './screens/PostJob';
import Profile from './screens/Profile';
import Search from './screens/Search';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Switch>
            <Route path={routes.signUpTalent} component={SignUpTalent} />
            <Route path={routes.signUpRecruiter} component={SignUpRecruiter} />
            <Route path={routes.signUp2} component={SignUp2} />
            <Route path={routes.signInRecruiter} component={SignInRecruiter} />
            <Route path={routes.signInTalent} component={SignInTalent} />

            <Route path={routes.landing} component={Landing} exact />
            <Route path={routes.landing2} component={Landing2} />

            <Route path={routes.jobdetails} component={Jobdetails} />
            <PrivateRoute path={routes.postjob} isRecruiter={true} component={PostJob} />
            <Route path={routes.profile} component={Profile} />
            <Route path={routes.search} component={Search} />

          </Switch>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
