import Container from "../components/Container";
import { Box, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Textarea, Checkbox, Menu, MenuButton, MenuList, MenuItem, Spinner } from "@chakra-ui/react"
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import React, { useState, useRef, Fragment, useEffect } from 'react';
import colors from '../theme/colors';
import { fontSizes, pageWidth } from '../theme/styles';
import { GoSearch, FaMapMarkerAlt, BsCaretDownFill, GoSettings, BiCurrentLocation } from "react-icons/all"
import MultiRangeSlider from "../components/multiRangeSlider/MultiRangeSlider";
import { auth, db } from "../firebase/base";
import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import PendingSpinner from "../components/Spinner/spinner";
import { filter } from "../SearchFunction/search";
import { State } from "../lib/lib";
const Search = ({ history }) => {
    const [pending, setPending] = useState(true)
    const [jobs, setJobs] = useState([])
    const [filterJobs, setFilterJobs] = useState([])
    const [users, setUsers] = useState([])
    const [filterUsers, setFilterUsers] = useState([])
    const [filterInput, setFilterInput] = useState("")
    const [remote, setRemote] = useState(false)
    const [employment, setEmployment] = useState("")
    const [location, setLocation] = useState("")
    const [priceMin, setPriceMin] = useState()
    const [priceMax, setPriceMax] = useState()
    const [max, setMax] = useState(1)
    const [searchButtonValue, setSearchButtonValue] = useState(true)
    const jobsArray = []
    const usersArray = []
    useEffect(async () => {
        window.scrollTo(0, 0)
        if (jobs.length == 0) {
            const querySnapshot = await getDocs(collection(db, "jobs"));
            querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                jobsArray.push(data)
            });
            setJobs(jobsArray)
            setFilterJobs(jobsArray)
        }
        if (users.length == 0) {
            const querySnapshot = await getDocs(collection(db, "users"));
            querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                usersArray.push(data)
            });
            setUsers(usersArray)
            setFilterUsers(usersArray)
        }
        setPending(false)
    }, [])

    const filterHandle = () => {
        const filterData = filter(jobs, "", { title: filterInput, remote, employment, location, priceMin, priceMax });
        const filterUser = filter("", users, { title: filterInput });
        setFilterUsers(filterUser)
        setFilterJobs(filterData)
    }
    return (
        <Container>
            <Box
                height={{ base: '36', md: '24' }}
                backgroundColor={colors.primary}
            ></Box>
            <Box
                alignItems="center"
                d="flex"
                backgroundColor={colors.pageBG}
                position="relative"
                pb="65px"
            >
                <Box
                    alignItems="center"
                    maxW={pageWidth}
                    margin="auto"
                    width="100%"
                    position="relative"
                >
                    <Box
                        d="flex"
                        flexDirection="column"
                        position="absolute"
                        width="100%"
                        background="linear-gradient(180deg, #2A2E3D 0%, #3C4254 100%)"
                        borderRadius="10px"
                        padding="52px 34px"
                        top="-95px"
                    >
                        <Box
                            d="flex"
                            flexDirection="row"
                            w="100%"
                        >
                            <InputGroup>
                                <Input onChange={(e) => setFilterInput(e.target.value)} placeholder="Search for Jobs or Talent" focusBorderColor="none" height="94px" pl="20px" mb="24px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} />
                                <InputRightElement width="unset" right={{ base: "20px", md: "100px" }} marginTop={{ base: "100px", md: "26px" }}>
                                    <Menu >
                                        <MenuButton color={{ base: "white", md: "unset" }} fontSize={{ base: "15px", md: "unset" }} as={Button} rightIcon={<BsCaretDownFill />} background="transparent" _hover="unset" fontWeight="400" >
                                            {searchButtonValue ? "Search for Jobs" : "Search for Talent"}
                                        </MenuButton>
                                        <MenuList  >
                                            <MenuItem onClick={(e) => setSearchButtonValue(true)}> Search for Jobs</MenuItem>
                                            <MenuItem onClick={(e) => setSearchButtonValue(false)}> Search for Talent</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </InputRightElement>
                                <InputRightElement onClick={() => filterHandle()} as="button" mt="26px" mr={{ base: "5px", md: "26px" }} children={<GoSearch fontSize="32px" />} />
                            </InputGroup>
                            <Box display={{ base: "none", md: "unset" }}> <GoSettings size={35} color={colors.secondary} style={{ marginTop: "26px", marginLeft: "32px" }} /></Box>
                        </Box>
                        {searchButtonValue &&
                            <Box
                                d="flex"
                                flexDirection={{ base: "column", md: "row" }}
                                w="100%"
                                pt="32px"
                            >
                                <Cardbox style={{ height: "140px" }} maxWidth="250px" >
                                    <Text mb="12px" color="#fff" fontSize={16}>Filter by Salary - Hourly Salary</Text>
                                    <MultiRangeSlider
                                        min={0}
                                        max={999999}
                                        onChange={({ min, max }) => { setPriceMin(min); setPriceMax(max) }}
                                    />
                                </Cardbox>
                                <Cardbox>
                                    <Text mb="12px" color="#fff" fontSize={16}>Filter by Location</Text>
                                    <InputGroup>
                                        <Select onChange={(e) => setLocation(e.target.value)} height="37px" placeholder="Select an Location" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}>
                                            {State.map(x =>
                                                <option value={x}>{x}</option>
                                            )}
                                        </Select>
                                        <InputRightElement mr="22px" children={<BiCurrentLocation fontSize="20px" />} />
                                    </InputGroup>
                                </Cardbox>
                                <Cardbox >
                                    <Text mb="12px" color="#fff" fontSize={16}>Employement Type</Text>
                                    <Select onChange={(e) => { setEmployment(e.target.value); filterHandle() }} height="37px" placeholder="Employement Type" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}>
                                        <option>Full Time</option>
                                        <option>Part Time</option>
                                        <option>Temporary</option>
                                        <option>Contractor</option>
                                        <option>Remote</option>
                                    </Select>
                                </Cardbox>
                                <Cardbox className="searchCheckbox" maxWidth="156px"  >
                                    <Text mb="12px" color="#fff" fontSize={16}>Job Type</Text>
                                    <Box d="flex"  >
                                        <Checkbox mt="5px" outline="none" mr="12px" style={{ display: "inline-block" }} defaultChecked={remote} onChange={() => setRemote(!remote)} />  <Text d="flex" color="#fff" >Remote  </Text>
                                    </Box>
                                </Cardbox>
                            </Box>
                        }
                    </Box>
                    {/* Hidden div */}
                    <Box
                        height={searchButtonValue ? { base: "800px", md: "330px" } : { base: "180px", md: "180px" }}
                        borderRadius="8px"
                    >
                    </Box>
                    {/* Hidden div */}
                    {pending ? <PendingSpinner /> :
                        searchButtonValue ?
                            filterJobs && filterJobs.map && filterJobs.slice(0).reverse().map(job =>
                                < Box
                                    key={job.id}
                                    background={colors.linearBG}
                                    d="flex"
                                    flexDirection="column"
                                    padding="32px"
                                    borderRadius="8px"
                                    mb="24px"
                                    cursor="pointer"
                                    onClick={() => history.push(`/details/${job.id}`)}
                                >
                                    {/* {job.salary > max && setMax(job.salary)} */}
                                    <Box
                                        d="flex"
                                        flexDirection="row"
                                        width="100%"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" color="#fff" fontSize={{ base: 18, md: "24px" }} fontWeight="600">{job.title}</Text>
                                        <Box
                                            d="flex"
                                            flexDirection="row"
                                            display={{ base: "none", md: "flex" }}
                                        >
                                            <Text fontSize={{ base: "12px", md: fontSizes.normal }} mr={{ base: "10px", md: "20px" }} fontWeight="bold" color="#E4E4E4">{job.company}</Text>
                                            <Text fontSize={{ base: "12px", md: fontSizes.normal }} mr={{ base: "10px", md: "20px" }} fontWeight="bold" color="#E4E4E4"><FaMapMarkerAlt size={18} style={{ display: "inline-block", marginBottom: "8px", marginRight: "8px" }} />{job.location}</Text>
                                            {job.salary.toString().replace(/\s/g, '').length == 4 && <Text fontSize={{ base: "12px", md: fontSizes.normal }} fontWeight="bold" color="#E4E4E4">{job.salary.toString().substring(0, 1) + "K"}</Text>}
                                            {job.salary.toString().replace(/\s/g, '').length == 5 && <Text fontSize={{ base: "12px", md: fontSizes.normal }} fontWeight="bold" color="#E4E4E4">{job.salary.toString().substring(0, 2) + "K"}</Text>}
                                            {job.salary.toString().replace(/\s/g, '').length == 6 && <Text fontSize={{ base: "12px", md: fontSizes.normal }} fontWeight="bold" color="#E4E4E4">{job.salary.toString().substring(0, 3) + "K"}</Text>}

                                        </Box>
                                    </Box>
                                    <Box
                                        mt="43px"
                                    >
                                        <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" color="#fff" fontSize={{ base: "12px", md: fontSizes.normal }}>{job.description}</Text>
                                    </Box>
                                </Box>
                            )
                            :
                            filterUsers && filterUsers.map && filterUsers.slice(0).reverse().map(user =>
                                <Box
                                    key={user.id}
                                    background={colors.linearBG}
                                    d="flex"
                                    flexDirection="column"
                                    padding="32px"
                                    borderRadius="8px"
                                    mb="24px"
                                    cursor="pointer"
                                    onClick={() => history.push({
                                        pathname: `/profile/${user.email}`
                                    })}
                                >
                                    <Box
                                        d="flex"
                                        flexDirection="row"
                                        width="100%"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Text color="#fff" fontSize="24px" fontWeight="600">{user.first}</Text>
                                        <Box
                                            d="flex"
                                            flexDirection="row"
                                        >
                                            <Text fontSize={fontSizes.normal} mr="20px" fontWeight="bold" color="#E4E4E4"><FaMapMarkerAlt size={18} style={{ display: "inline-block", marginBottom: "8px", marginRight: "8px" }} />{user.state}</Text>
                                        </Box>
                                    </Box>
                                    <Box
                                        mt="43px"
                                    >
                                        {/* <Text color="#fff" fontSize={fontSizes.normal}>{job.description}</Text> */}
                                    </Box>
                                </Box>
                            )
                    }
                </Box>
            </Box>
        </Container >
    )
}

export default Search
const Cardbox = ({ children, ...props }) => {
    return (
        <Box {...props}

            backgroundColor={colors.primary}
            padding="16px"
            pb="55px"
            boxShadow=" 0px 0px 25px rgba(0, 0, 0, 0.16);"
            borderRadius="10px"
            mr={{ md: "12px", xl: "22px" }}
            mb={{ base: "12px", md: "0" }}
            maxH="140px"
            height="100%"
            width="100%"
        >
            {children}
        </Box>
    )
}