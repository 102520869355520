import { Box, Text } from '@chakra-ui/layout';
import React, { useContext } from 'react';
import colors from '../../theme/colors';
import { fontSizes, pageWidth } from '../../theme/styles';
import FooterLink from './FooterLink';
import FooterIcon from './FooterIcon';
import Logo from '../Logo';
import { ImFacebook, ImTwitter, ImInstagram, ImLinkedin, SiTiktok } from 'react-icons/all';
import { RiSendPlaneFill } from 'react-icons/ri';
import routes from '../../constants/routes';
import { AuthContext } from "../../firebase/Auth"
const Footer = () => {


  
  return (
    <Box d="flex" flexDirection="column">
      <Box
        padding={{ base: "10px" }}
        bgColor={colors.primary}
        alignItems="center"
        justifyContent="center"
        d="flex"
        color="#fff"
      >
        <Box
          maxW={pageWidth}
          d="flex"
          flex="1"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          px={{ base: "20px" }}
        >
          <Box py="5">
            <Box
              d="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Logo />
              <Text fontWeight="bold" mx="5">Dialogge</Text>
            </Box>
            <Text mt="5" fontSize={fontSizes.normal}>
              Recruitment services to help you start your career with ease
            </Text>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              mt="5"
            >

              <FooterIcon href="https://www.tiktok.com/@dialogge_" Icon={<SiTiktok />} />
              <FooterIcon href="https://www.instagram.com/dialoggeco/" Icon={<ImInstagram />} />
              <FooterIcon href="https://www.linkedin.com/company/76217452" Icon={<ImLinkedin />} />
              <FooterIcon Icon={<RiSendPlaneFill />} />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" marginTop={{ base: "20px" }}>
            <Title>Quick Links</Title>
            <FooterLink to="/">Home</FooterLink>
            <FooterLink to={routes.postjob}>Post Job</FooterLink>
            <FooterLink to={routes.search}>Search</FooterLink>
            <FooterLink to={routes.landing2}>About Us</FooterLink>
          </Box>
          <Box display="flex" flexDirection="column" marginTop={{ base: "20px" }}>
            <Title>Support</Title>
            <FooterLink>Help</FooterLink>
            <FooterLink>Terms Of Service</FooterLink>
            <FooterLink>Privacy Policy</FooterLink>
            <FooterLink>Customer Policy</FooterLink>
            <FooterLink>Contact Support</FooterLink>
          </Box>
        </Box>
      </Box>
      <Box bgColor="#000" alignItems="center" justifyContent="center" d="flex">
        <Box
          maxW={pageWidth}
          d="flex"
          flex="1"
          flexDirection="row"
          justifyContent="space-between"
          py="8"
        >
          <Text color="#fff">Copyrights Protected, 2021</Text>
          <Text color="#fff">All Rights Reserved</Text>
        </Box>
      </Box>
    </Box>
  );
};

const Title = ({ children, ...props }) => {
  return (
    <Text fontSize={fontSizes.normal} {...props}>
      {children}
    </Text>
  );
};

export default Footer;
