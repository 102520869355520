const colors = {
  primary: '#131A33',
  secondary: '#99EFD0',
  ternary: '#323A51',
  dark: '#13151E',
  text: '#232941',
  pageBG: '#323A51',
  linearBG:'linear-gradient(180deg, #21273E 0%, #2B334B 100%)'
};

export default colors;
