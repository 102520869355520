import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAlert } from "react-alert";
export const UploadFile = async (file, folderPath, funciton, alert) => {

    try {
        const storage = getStorage();
        let fileLinks = []
        for (let index = 0; index < file.length; index++) {

            const storageRef = ref(storage, `${folderPath}/` + `${Date.now()}-${file[index].name}`);
            const uploadTask = uploadBytesResumable(storageRef, file[index]);

            // Listen for state changes, errors, and completion of the upload.
            await uploadTask.on('state_changed',
                (snapshot) => {

                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;
                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        funciton(downloadURL, fileLinks)
                    });
                }
            );
        }
    } catch (error) {
        console.log(error)
    }
};
