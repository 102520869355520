import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import React from 'react';
import colors from '../../theme/colors';
import { pageWidth } from '../../theme/styles';
import Logo from '../Logo';
import NavbarLink from './NavbarLink';
import { signOut } from "firebase/auth"
import { auth } from "../../firebase/base";
import routes from "../../constants/routes"
import { Link } from 'react-router-dom';
import { BsCaretDownFill } from "react-icons/all"
import { Avatar, Menu, MenuButton, MenuList, MenuItem, Text, Stack } from "@chakra-ui/react"
import { useHistory } from 'react-router-dom';

const AccountNavbar = ({ height = '150px', currentUser }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => setIsOpen(!isOpen);
    return (
        <Box
            backgroundColor={colors.primary}
            height={height}
            alignItems="center"
            pt="20px"
            px={{ base: "30px", md: "0px" }}
        >
            <Box
                d="flex"
                flex="1"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                maxW={pageWidth}
                margin="auto"
                alignItems={{ base: "unset", md: "center" }}
            >
                <Logo />
                <Box
                    d="flex"
                    flexDirection="row"
                    justifyContent="center"
                    py={{ base: "20px" }}
                    borderRadius="10px"
                    zIndex="1"
                    background={{ base: colors.primary, md: "none" }}
                >
                    <MenuLinks isOpen={isOpen} currentUser={currentUser} />
                    <MenuToggle toggle={toggle} isOpen={isOpen} />
                    {/* <NavbarLink to="/">Home</NavbarLink>
                    <NavbarLink to="/search">Search</NavbarLink>
                    <NavbarLink>About Us</NavbarLink>
                    {currentUser.userType == "recruiter" && < NavbarLink to="/postjob">Post Job</NavbarLink>}

                    <Menu >
                        <MenuButton _active="unset" flexDirection="row" as={Button} rightIcon={<BsCaretDownFill />} color="#fff" background="transparent" _hover="unset" fontWeight="700" >
                            <Box d="flex" alignItems="center">
                                <Avatar d="flex" size="md" name={currentUser.first} src={currentUser.Avatar} />
                                <Text d="flex" ml="10px">{currentUser.first}</Text>
                            </Box>

                        </MenuButton>
                        <MenuList>
                            <MenuItem as={Link} to={{ pathname: routes.profile }}>Profile</MenuItem>
                            <MenuItem onClick={() => signOut(auth)}>Logout</MenuItem>
                        </MenuList>
                    </Menu> */}
                </Box>
            </Box>
        </Box >
    );
};

const MenuIcon = () => (
    <svg
        width="24px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        fill="white"
    >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
    return (
        <Box display={{ base: "block", md: "none" }} onClick={toggle}>
            <MenuIcon />
        </Box>
    );
};


const MenuLinks = ({ isOpen, currentUser }) => {
    const history = useHistory();
    return (
        <Box
            display={{ base: isOpen ? "block" : "none", md: "block" }}
            flexBasis={{ base: "100%", md: "auto" }}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            p={{ base: "20px" }}
        >
            <Stack
                align="center"
                justify={["center", "space-between", "flex-end", "flex-end"]}
                direction={["column", "column", "row", "row"]}
                pt={[4, 4, 0, 0]}

            >
                <NavbarLink to="/">Home</NavbarLink>
                <NavbarLink to="/search">Search</NavbarLink>
                <NavbarLink to="/aboutus">About </NavbarLink>
                {currentUser.userType == "recruiter" && < NavbarLink to="/postjob">Post Job</NavbarLink>}
                <Menu >
                    <MenuButton _active="unset" flexDirection="row" as={Button} rightIcon={<BsCaretDownFill />} color="#fff" background="transparent" _hover="unset" fontWeight="700" >
                        <Box d="flex" alignItems="center">
                            <Avatar d="flex" size="md" name={currentUser.first} src={currentUser.avatar} />
                            <Text d="flex" ml="10px">{currentUser.first}</Text>
                        </Box>

                    </MenuButton>
                    <MenuList>
                        <MenuItem as={Link} to={{ pathname: `/profile/${currentUser.email}` }}>Profile</MenuItem>
                        <MenuItem onClick={() => { signOut(auth); history.push("/") }}>Logout</MenuItem>
                    </MenuList>
                </Menu>
            </Stack>
        </Box>
    );
};
export default AccountNavbar;
