import { Box } from "@chakra-ui/layout";
import { Textarea, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useSpeechToText from 'react-hook-speech-to-text';



const SpeechToText = ({ status = false, subtitle }) => {

    const [newresult, setNewresult] = useState([])
    const [array, setArray] = useState([])
    const [i, setI] = useState(0)
    const {
        error,
        interimResult,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
        setResults
    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
    });

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        sessionStorage.removeItem("subtitle")
        sessionStorage.setItem("subtitle", JSON.stringify(array))
    };

    useEffect(() => {
        if (!error) {
            if (status) {
                if (!isRecording) {
                    startSpeechToText()
                    console.log("Now listening...")
                }
            }
            else {
                try {
                    stopSpeechToText()
                    console.log("Stopped Listening");
                } catch (error) {
                    console.log("Not supported")
                }

            }
        }
        return () => console.log("")
    }, [status]);

    useEffect(() => {
        let interval
        if (status) {
            interval = setTimeout(async () => {
                results.map(x => setNewresult(oldArray => [...oldArray, x.transcript]))
                setArray(oldArray => [...oldArray, newresult.join("")])
                setNewresult([])
                setResults([])
            }, 4000);
        }
        return () => clearInterval(interval)
    }, [array])

    return (
        < Box id="speechBox" margin="auto" mt="10px" onClick={handleSubmit} >
            <noscript>x</noscript>
            {status &&
                <form >
                    <Textarea m="auto" width="100%" mb="20px" background="white" id="subtitleTxt" value={interimResult}></Textarea>
                    {results.map((x, index) =>
                        <Text key={index} color="white" ml="5px" d="inline-block" id="subtitleTxt">{x.transcript}</Text>
                    )}
                    <button id="printbtn" type="submit" className="btn">

                    </button>
                </form>
            }
        </Box >


    );
};

export default SpeechToText;