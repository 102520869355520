import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from '@chakra-ui/layout';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const LinkWithChakra = (props) => {
  const {
    to = '',
    replacePath= false,
    isExternal= false,
    children,
    ...chakraProps
  } = props;
  const location = useLocation();
  const history = useHistory();

  const path = to || '#';

  const go = (e) => {
    e.preventDefault();
    if (to && location.pathname !== to) {
      history[replacePath ? 'replace' : 'push'](to);
    }
  }

  return (
    <Link
      d="flex"
      href={path}
      isExternal={isExternal}
      onClick={go}
      {...chakraProps}
    >
      {children}
      {isExternal && (
        <ExternalLinkIcon mx="2px" />
      )}
    </Link>
  );
};

export default LinkWithChakra;
