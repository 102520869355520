import React, { useState, Fragment } from 'react';
import colors from '../../theme/colors';
import { pageWidth } from '../../theme/styles';
import { ImFacebook, ImTwitter, BiPlus, ImDribbble, FaInstagram, RiLinkedinFill, FaTelegramPlane, AiOutlineMail } from 'react-icons/all';
import { Box, Heading, Stack, Text, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, Button, Input, FormLabel, Select } from '@chakra-ui/react';
import ProfileIcon from "../RecruiterProfileTop/ProfileSocialicon"
import { GoPencil } from 'react-icons/all';
import { State } from '../../lib/lib';
import { auth, db } from "../../firebase/base";
import { getDoc, doc, arrayUnion, updateDoc } from "firebase/firestore";
import ConnectSocialMediaPopover from '../ProfileImageAndPInfo/ConnectSocialMediaPopover';
import { useAlert } from "react-alert"
import { UploadFile } from "../../firebase/UploadFile"
const RecruiterProfileTop = ({ currentUser }) => {
    const SocialMediaTabs = [
        {
            key: 'facebook',
            icon: <ImFacebook />,
            title: "Enter your Facebook username",
            placeholder: 'facebook.com/'
        },
        {
            key: 'twitter',
            icon: <ImTwitter />,
            title: "Enter your Twitter username",
            placeholder: "twitter.com/",
        },
        {
            key: 'instagram',
            icon: <FaInstagram />,
            title: "Enter your Instagram username",
            placeholder: "instagram.com/",
        },
        {
            key: 'linkedin',
            icon: <RiLinkedinFill />,
            title: "Enter your LinkedIn username",
            placeholder: "linkedin.com/in/",
        },
        {
            key: 'telegram',
            icon: <FaTelegramPlane />,
            title: "Enter your Telegram username",
            placeholder: "",
        },
    ];
    const alert = useAlert()
    const [edit, setEdit] = useState(false)
    const [first, setFirst] = useState(currentUser.first);
    const [last, setLast] = useState(currentUser.last);
    const [state, setState] = useState(currentUser.state);
    const [city, setCity] = useState(currentUser.city);
    const [website, setWebsite] = useState(currentUser.website);
    const [instagram, setInstagram] = useState(currentUser.instagram);
    const [facebook, setFacebook] = useState(currentUser.facebook);
    const [twitter, setTwitter] = useState(currentUser.twitter);
    const [linkedin, setLinkedin] = useState(currentUser.linkedin);
    const [telegram, setTelegram] = useState(currentUser.telegram);

    const updateProfile = async () => {
        try {
            const docRef = doc(db, "users", currentUser.email);
            await updateDoc(docRef, {
                first: first,
                last: last,
                state: state,
                city: city,
                website: website,
                instagram: instagram,
                twitter: twitter,
                linkedin: linkedin,
                telegram: telegram,
                facebook: facebook
            });

            alert.success("SUCCESSFUL")
        } catch (error) {
            alert.error(error.code)
        }

    }

    const callbackSocial = (key, value, keys) => {
        let i = true
        keys && keys.length !== 0 && keys.map(x => {

            if (x.key == "instagram") {
                setInstagram(x.value)
            }
            if (x.key == "linkedin") {
                setLinkedin(x.value)
            }
            if (x.key == "telegram") {
                setTelegram(x.value)
            }
            if (x.key == "twitter") {
                setTwitter(x.value)
            }
            if (x.key == "facebook") {
                setFacebook(x.value)
            }
            if (i) {
                alert.success("Successful")
            }
            i = false
        })
    }

    const handleProfileAvatar = (e) => {
        if (e.target.files[0]) {
            if (e.target.files[0].size > 5000000) {
                alert.error("File Size Too Large!");
                e.target.value = "";
            }
            else {
                if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == 'image/png') {
                    var reader = new FileReader();
                    // Read in the image file as a data URL.
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = function (evt) {
                        if (evt.target.readyState == FileReader.DONE) {
                            var output = document.getElementById('profilfoto');
                            output.style.background = `url(${evt.target.result})`
                        }
                    }

                    UploadFile([e.target.files[0]], "avatar", uploadAvatar, alert)
                    alert.info("You will be notified when the download is complete")
                    console.log("upload")
                }
                else {
                    alert.error("Invalid File Format.")
                }
            }
        }
    }
    const uploadAvatar = async (url, fileLinks) => {
        try {
            if (url) {
                const docRef = doc(db, "users", currentUser.email);
                await updateDoc(docRef, {
                    avatar: url
                });

                alert.success("SUCCESSFUL")
            }
            else {
                alert.error("ERROR")
            }
        } catch (error) {
            alert.error(error.code)
        }

    }
    return (
        <Box
            d="flex"
            w="100%"
            alignItems="center"
            justifyContent="center"
            paddingTop={68}
            bgColor={colors.pageBG}
        >
            <Box
                d="flex"
                w="100%"
                maxW={pageWidth}
                padding="32px 0"
                style={{ gap: 50 }}
                borderBottom="1px solid #8D93A8"
                marginBottom="48px"
                flexDirection={{ base: "column", md: "row" }}
            >

                <Box
                    d="flex"
                >
                    {/* Profile image area */}
                    <label for="uploadAvatar">
                        <Box
                            id="profilfoto"
                            d="flex"
                            backgroundImage={currentUser.avatar ? `url(${currentUser.avatar})` : 'linear-gradient(180deg, #21273E 0%, #2B334B 100%)'}
                            backgroundSize="161px !important"
                            backgroundRepeat="no-repeat "
                            w={161}
                            h={161}
                            justifyContent="center"
                            alignItems="center"
                            borderRadius={12}
                            cursor="pointer"
                        >
                            {/* <BiPlus size={32} color="#fff" /> */}
                        </Box>
                        <input type="file" id="uploadAvatar" style={{ display: "none" }} onChange={handleProfileAvatar} />
                    </label>
                </Box>

                {/* Profile info area */}
                <Box
                    d="flex"
                    flex={1}
                    flexDirection="column"
                    alignItems="stretch"
                    justifyContent="space-between"
                >
                    {/* User name, address and edit profile area */}
                    <Box
                        d="flex"
                        justifyContent="space-beween"
                        alignItems="flex-start"
                        boxSizing="border-box"
                        padding="5px 0"
                        flexDirection={{ base: "column-reverse", md: "row" }}
                    >
                        <Box
                            w={{ base: "400px", md: "100%" }}
                            h="auto"
                        >
                            <Stack color="#FFFFFF">
                                {!edit ?
                                    <Fragment>
                                        <Heading as="h3" size="2xl">
                                            {first + " " + last.substring(0, 3) + "."}
                                        </Heading>
                                        <Text fontSize="lg">
                                            {currentUser.state + ", " + currentUser.state}
                                        </Text>
                                    </Fragment> :
                                    <Fragment>
                                        <Box>
                                            <FormLabel color="#fff" fontWeight="600" >
                                                First Name
                                            </FormLabel>
                                            <Input onChange={(e) => { setFirst(e.target.value) }} value={first} type="text" focusBorderColor="none" width="50%" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} />
                                        </Box>
                                        <Box>
                                            <FormLabel color="#fff" fontWeight="600" >
                                                Last Name
                                            </FormLabel>
                                            <Input onChange={(e) => { setLast(e.target.value) }} value={last} type="text" focusBorderColor="none" width="50%" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} />
                                        </Box>
                                    </Fragment>
                                }

                                <Text fontSize="lg">
                                    {edit &&
                                        <Fragment>
                                            <Box>
                                                <FormLabel color="#fff" fontWeight="600" >
                                                    State
                                                </FormLabel>
                                                <Select onChange={(e) => { setState(e.target.value) }} value={state} type="text" focusBorderColor="none" width="50%" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}>
                                                    {State.map(x =>
                                                        <option value={x}>{x}</option>
                                                    )}
                                                </Select>

                                            </Box>
                                            <Box>
                                                <FormLabel color="#fff" fontWeight="600" >
                                                    City
                                                </FormLabel>
                                                <Input onChange={(e) => { setCity(e.target.value) }} value={city} type="text" focusBorderColor="none" width="50%" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} />
                                            </Box>
                                            <Box>
                                                <FormLabel color="#fff" fontWeight="600" >
                                                    Web Site
                                                </FormLabel>
                                                <Input onChange={(e) => { setWebsite(e.target.value) }} value={website} type="text" focusBorderColor="none" width="50%" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }} />
                                            </Box>
                                            <Box
                                                alignItems="center"
                                                d="flex"
                                                backgroundColor={colors.pageBG}
                                            >
                                                <Box
                                                    maxW={pageWidth}
                                                    width="100%"
                                                >
                                                    <Box
                                                        d="flex"
                                                        flexDirection="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Button onClick={() => updateProfile()} bgColor={colors.secondary} color={colors.text} width="150px" py="25px" mt="32px" >
                                                            SAVE
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Fragment>
                                    }
                                </Text>
                            </Stack>
                        </Box>
                        {
                            edit &&
                            <Box my={{ base: "20px", md: "0" }}>
                                <ConnectSocialMediaPopover parentCallback={callbackSocial} items={SocialMediaTabs} />
                            </Box>
                        }

                        <Box height="auto"  >
                            <Box
                                onClick={() => setEdit(!edit)}
                                d="flex"
                                w="36px"
                                h="36px"
                                p="0"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="18px"
                                cursor="pointer"
                                bgColor={colors.secondary}
                            >
                                <GoPencil />
                            </Box>

                        </Box>

                    </Box>

                    {/* Website url and social media area */}
                    <Box
                        d="flex"
                        justifyContent="space-between"
                        flexDirection={{ base: "column", md: "row" }}
                    >
                        <Box mb={{ base: "15px", md: undefined }}>
                            {currentUser.website &&
                                <Heading as="a" href={currentUser.website} target="_blank" color="#fff" size="xs">
                                    View Website
                                </Heading>
                            }
                        </Box>
                        <Box>
                            {currentUser.facebook && <ProfileIcon to={currentUser.facebook} Icon={<ImFacebook />} />}
                            {currentUser.twitter && <ProfileIcon to={currentUser.twitter} Icon={<ImTwitter />} />}
                            {currentUser.instagram && <ProfileIcon to={currentUser.instagram} Icon={<FaInstagram />} />}
                            {currentUser.telegram && <ProfileIcon to={`https://t.me/${currentUser.telegram}`} Icon={<FaTelegramPlane />} />}
                            {currentUser.linkedin && <ProfileIcon to={currentUser.linkedin} Icon={<RiLinkedinFill />} />}
                            <ProfileIcon to={"mailto:" + currentUser.email} bgColor={colors.secondary} Icon={<AiOutlineMail />} ml={{ md: "24px" }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default RecruiterProfileTop;