import { IconButton } from '@chakra-ui/button';
import React from 'react';
import colors from '../../theme/colors';

const FooterIcon = ({ Icon, bgColor = '#fff', href, ...props }) => {
  return (
    <IconButton
      cursor="pointer"
      as="a"
      href={href}
      mr="2"
      bgColor={bgColor}
      _hover={{
        bg: colors.secondary
      }}
      rounded="full"
      color={colors.primary}
      icon={Icon}
      target="_blank"
      {...props}
    />
  );
};

export default FooterIcon;
