import { Box, Text } from '@chakra-ui/layout';
import PendingSpinner from "../components/Spinner/spinner.js";
import { Button } from '@chakra-ui/button';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import colors from '../theme/colors';
import { fontSizes, pageWidth } from '../theme/styles';
import Container from '../components/Container';
import { FaMapMarkerAlt } from "react-icons/fa"
import { auth, db } from "../firebase/base";
import { getDoc, doc, arrayUnion, updateDoc } from "firebase/firestore";
import { AuthContext } from "../firebase/Auth"
import { Redirect } from 'react-router';
import { useAlert } from 'react-alert';
import { send } from 'emailjs-com';
import { user_id, service_id, template_id } from "../constants/emailjs"
const JobDetails = ({ match, history }) => {
    const { currentUser } = useContext(AuthContext)
    const [detail, setDetail] = useState("");
    const [pending, setPending] = useState(true);
    const [refresh, setRefresh] = useState("");
    const [from_name, setFromname] = useState("");
    const [to_name, setToname] = useState("");


    const alert = useAlert()
    useEffect(async () => {
        const docRef = doc(db, "jobs", match.params.title);
        const docSnap = await getDoc(docRef);
        if (docSnap.data()) {
            setDetail(docSnap.data())
            setPending(false)
        }
        else {
            alert.error("Not Found")
            setTimeout(() => {
                history.push("/")
            }, 1000);
        }
    }, [refresh]);

    const applyJob = async () => {
        try {
            const data = {
                to_email: detail.owner,
                to_name: currentUser.first + " " + currentUser.last,
                to_resume: currentUser.dataResume,
                to_city: currentUser.city + "," + currentUser.state,
                workAuth: currentUser.workauth,
                to_link: `https://${window.location.hostname}/profile/${currentUser.email}`
            }
            const docRef = doc(db, "jobs", match.params.title);
            await updateDoc(docRef, {
                registerUser: arrayUnion(currentUser.email)
            });
            send(
                service_id,
                template_id,
                data,
                user_id
            )
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                })
                .catch((err) => {
                    console.log('FAILED...', err);
                });
            alert.success("Successful")
            setRefresh("x")
        } catch (error) {
            console.log(error)
        }

    }

    return (
        detail ?
            <Container>
                <Box
                    alignItems="center"
                    d="flex"
                    backgroundColor={colors.pageBG}
                    pt="100px"
                >
                    <Box
                        alignItems="center"
                        maxW={pageWidth}
                        margin="auto"
                        width="100%"
                    >
                        {pending ? <PendingSpinner /> :
                            <Fragment>
                                <Box
                                    d="flex"
                                    flexDirection="row"
                                    alignItems="baseline"
                                >
                                    <Text fontSize={{ base: "40", md: "48" }} fontWeight="bold" color="#fff">{detail.title}</Text>
                                </Box>
                                <Box
                                    d="flex"
                                    flexDirection="row"
                                    alignItems="baseline"
                                    mt="32px"
                                >
                                    <Text fontSize={fontSizes.normal} mr="20px" fontWeight="bold" color="#E4E4E4">{detail.company}</Text>
                                    <Text fontSize={fontSizes.normal} mr="20px" fontWeight="bold" color="#E4E4E4"><FaMapMarkerAlt size={18} style={{ display: "inline-block", marginBottom: "8px", marginRight: "8px" }} />{detail.location}</Text>
                                    {/* <Text fontSize={fontSizes.normal} mr="20px" fontWeight="bold" color="#E4E4E4">{detail.employment}</Text> */}
                                    {detail.salary.toString().replace(/\s/g, '').length == 4 && <Text fontSize={{ base: "12px", md: fontSizes.normal }} fontWeight="bold" color="#E4E4E4">{detail.salary.toString().substring(0, 1) + "K"}</Text>}
                                    {detail.salary.toString().replace(/\s/g, '').length == 5 && <Text fontSize={{ base: "12px", md: fontSizes.normal }} fontWeight="bold" color="#E4E4E4">{detail.salary.toString().substring(0, 2) + "K"}</Text>}
                                    {detail.salary.toString().replace(/\s/g, '').length == 6 && <Text fontSize={{ base: "12px", md: fontSizes.normal }} fontWeight="bold" color="#E4E4E4">{detail.salary.toString().substring(0, 3) + "K"}</Text>}
                                </Box>
                                <Box
                                    d="flex"
                                    flexDirection="row"
                                    alignItems="baseline"
                                    mt="32px"
                                    pb="48px"
                                    borderBottom="1px solid #8D93A8"
                                >
                                    {detail.tags && detail.tags.map && detail.tags.map(tag =>
                                        <Box px="18px" d="flex" justifyContent="center" alignItems="center" mr="8px" borderRadius="50px" color="#36426C" background={colors.secondary} h="50px">{tag}</Box>
                                    )}

                                </Box>
                                <Box
                                    d="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    mt="48px"
                                    pb="64px"

                                >
                                    <Text overflow="hidden" lineHeight="22px" fontSize={fontSizes.normal} color="#fff">{detail.description}
                                        {/* {/* <UnorderedList ml="30px">
                                <ListItem> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
                                </ListItem><br />
                                <ListItem> If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
                                </ListItem><br />
                                <ListItem>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                                </ListItem>
                            </UnorderedList><br /> */}
                                    </Text>
                                </Box>
                            </Fragment>
                        }

                    </Box>
                </Box >
                <Box
                    alignItems="center"
                    d="flex"
                    backgroundImage={colors.linearBG}
                >
                    <Box
                        alignItems="center"
                        maxW={pageWidth}
                        margin="auto"
                        width="100%"
                    >
                        <Box
                            d="flex"
                            flexDirection="row"
                            alignItems="center"
                            mt="64px"
                        >
                            {currentUser && currentUser.userType == "talent" && <Text maxW="437px" w="100%" color="#fff" lineHeight="22px" fontSize={fontSizes.small}>You have the necessary Skills mentioned for this Job. Apply to get considered by the employer.</Text>}
                        </Box>
                        <Box
                            d="flex"
                            pb="86px"
                            mt="32px"
                        >

                            {currentUser && currentUser.userType == "talent" && (
                                detail.registerUser.filter(x => x == currentUser.email).length !== 0 ?
                                    < Button
                                        disabled
                                        size="lg"
                                        bgColor={colors.secondary}
                                        color={colors.primary}
                                        _hover={{
                                            bg: colors.secondary
                                        }}
                                        fontWeight="500"
                                        d="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        w="187px"
                                        h="61"
                                    >
                                        APPLIED FOR
                                    </Button> :
                                    <Button
                                        onClick={() => applyJob()}
                                        size="lg"
                                        bgColor={colors.secondary}
                                        color={colors.primary}
                                        _hover={{
                                            bg: colors.secondary
                                        }}
                                        fontWeight="500"
                                        d="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        w="187px"
                                        h="61"
                                    >
                                        APPLY
                                    </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container > : <div></div>
    )
}
export default JobDetails