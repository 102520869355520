import React, { useEffect, useState } from "react";
import { auth, db } from "./base.js";
import { collection, query, where, getDoc, doc } from "firebase/firestore";
import PendingSpinner from "../components/Spinner/spinner.js";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUser2, setCurrentUser2] = useState(null);
    const [pending, setPending] = useState(true);
    const [email, setEmail] = useState("");


    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                const docRef = doc(db, "users", user.email);
                const docSnap = await getDoc(docRef);
                setCurrentUser(docSnap.data())
            }
            else {
                setCurrentUser(null)
            }
            setPending(false)
        });

    }, []);

    if (pending) {
        return <PendingSpinner />
    }
    return (
        <AuthContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};