import { Box } from '@chakra-ui/layout';
import React, { useContext } from 'react';
import Navbar from './navbar/Navbar';
import AccountNavbar from './navbar/AccountNavbar';
import Footer from './footer/Footer';
import { AuthContext } from "../firebase/Auth"
const Container = ({ children }) => {
  const { currentUser } = useContext(AuthContext)
  return (
    <Box>
      {currentUser ? <AccountNavbar currentUser={currentUser} /> : <Navbar />}
      <main>{children}</main>
      <Footer />
    </Box>
  );
};

export default Container;
