import React from 'react';
import { Link as CLink } from '@chakra-ui/layout';
import { Link as RLink } from 'react-router-dom';
import { fontSizes } from '../../theme/styles';

const FooterLink = ({ to = '', children = '', ...props }) => {
  return (
    <CLink
      color="#fff"
      as={RLink}
      to={to}
      textTransform="uppercase"
      fontSize={fontSizes.small}
      py="1"
      {...props}
    >
      {children}
    </CLink>
  );
};

export default FooterLink;
