import React, { useContext, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../firebase/Auth";
import routes from "./routes";

const ProtectedRoute = ({ isRecruiter, component: RouteComponent, ...rest }) => {

    const { currentUser } = useContext(AuthContext);
    return (
        <Fragment>
            <Route
                {...rest}
                render={props => {

                    if (!currentUser) {
                        return <Redirect to={routes.signInTalent} />
                    }
                    if (isRecruiter === true && currentUser.userType !== 'recruiter') {
                        return <Redirect to="/" />
                    }
                    return <RouteComponent {...props} />
                }}
            />
        </Fragment>
    )
}
export default ProtectedRoute