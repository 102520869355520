import { Box, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import React, { useCallback, useContext, useState } from "react";
import colors from '../theme/colors';
import { fontSizes, pageWidth } from '../theme/styles';
import Container from '../components/Container';
import Navbar from '../components/navbar/Navbar';
import { Link as CLink } from '@chakra-ui/layout';
import Breadcrumb from '../components/Breadcrumb';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, db } from "../firebase/base";
import { Link as RLink, useHistory, Redirect } from 'react-router-dom';
import { AuthContext } from "../firebase/Auth"
import { useAlert } from "react-alert"
import { UploadFile } from "../firebase/UploadFile"
import Validate from "../components/Validate"
import { getDoc, doc } from "firebase/firestore";
import { color } from '@chakra-ui/styled-system';
import { State } from "../lib/lib"
import video2 from "../assets/video/video2.mp4"
import routes from '../constants/routes';
const SignUpTalent = () => {

    const [email, setEmail] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [number, setNumber] = useState("");
    const [state, setState] = useState("")
    const [city, setCity] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [jobtitle, setJobtitle] = useState("");
    const [idialJobs, setIdialJobs] = useState("");
    const [workauth, setWorkauth] = useState("");
    const [resume, setResume] = useState("");
    const [dataResume, setDataResume] = useState("");
    const [resumeValue, setResumeValue] = useState("");
    const [error, setError] = useState({});
    const alert = useAlert()
    const history = useHistory();

    const { currentUser } = useContext(AuthContext)
    if (currentUser) {
        return <Redirect to={routes.search} />
    }
    const data = {
        email,
        first,
        last,
        number,
        jobtitle,
        idialJobs,
        state,
        city,
        password,
        dataResume,
        workauth,
        userType: "talent"
    }

    const handleLogin = async event => {
        const errors = valid()
        setError(errors);

        if (Object.keys(errors).length === 0) {
            const docRef = doc(db, "users", email);
            const docSnap = await getDoc(docRef);

            if (!docSnap.data()) {
                history.push({ pathname: "/sign-up-2", state: { data } });
            }
            else {
                alert.error("Already using email")
            }
        }

    }

    const handleChange = (e) => {
        if (e.target.files[0]) {
            if (e.target.files[0].size > 5000000) {
                alert.error("File Size Too Large!");
                e.target.value = "";
            }
            else {
                if (e.target.files[0].type == "application/msword" || e.target.files[0].type == 'application/pdf') {
                    setResumeValue(e.target.files[0].name)
                    UploadFile([e.target.files[0]], "resume", uploadResume, alert)
                    alert.info("You will be notified when the download is complete")
                    console.log("upload")
                }
                else {
                    alert.error("Invalid File Format.")
                }
            }
        }
    }
    const uploadResume = (url, fileLinks) => {
        data.resume = url
        setDataResume(url)
        alert.success("Successful")

    }

    const valid = () => {
        const errors = {};
        if (!email) errors.email = "E-Mail field is required.";
        if (!first) errors.first = "Firs Name field is required.";
        if (!last) errors.last = "Last Name field is required.";
        if (!state) errors.state = "State field is required.";
        if (!city) errors.city = "City field is required.";
        if (password.length < 8) errors.password = "Passwords must be at least 8 characters in length";
        if (repassword.length < 8) errors.repassword = "Passwords must be at least 8 characters in length";
        if (password !== repassword) errors.password = "Passwords do not match";
        if (password !== repassword) errors.repassword = "Passwords do not match";
        if (!password) errors.password = "Passwords field is required";
        if (!repassword) errors.repassword = "Confirm password field is required";
        return errors;
    }
    const errors = { ...error };
    return (
        <div>
            <Navbar />
            <Breadcrumb boolean={true} videoUrl={video2} title="  Don't get overlooked, GET Noticed!
                        If employers on average spend 6 seconds looking at your resume, why not give yourself the opportunity to voice your expertise, goals, passions & let them see why you would be a perfect
                        fit for their team!" />
            <Box
                alignItems="center"
                d="flex"
                backgroundColor={colors.pageBG}
                pt="30px"
            >
                <Box
                    alignItems="center"
                    maxW={pageWidth}
                    margin="auto"
                    width="100%"
                >
                    {/* <Box mb="20px" padding="20px" lineHeight={{ base: "24px", md: "30px" }} fontSize={{ base: "16px", md: "26px" }} fontWeight="bold" color="#fff" background={colors.linearBG}>
                        Dont get overlooked, GET Noticed!
                        If employers on average spend 6 seconds looking at your resume, why not give yourself the opportunity to voice your expertise, goals, passions & let them see why you would be a perfect
                        fit for their team!
                    </Box> */}

                    <Box
                        d="flex"
                        flexDirection="row"
                        alignItems="baseline"
                        mb="24px"
                    >
                        <CLink
                            as={RLink}
                            to={routes.signUpRecruiter}
                            fontSize={{ base: "10", md: "16" }}
                            fontWeight="bold"
                            textTransform="uppercase"
                            letterSpacing="2px"
                            color={colors.secondary}
                        >
                            &#8592;    SIGN UP AS EMPLOYER
                        </CLink>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection="row"
                        alignItems="baseline"
                    >
                        <Text fontSize={{ base: "34px", md: "48" }} fontWeight="bold" color="#fff">Sign Up as Talent</Text>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="baseline"
                        mt="64px"
                    >
                        <FormControl mr={{ md: "5" }}>
                            <Formlabel>First Name</Formlabel>
                            <FormInput onChange={(e) => setFirst(e.target.value)} placeholder="Enter text here" type="text" />
                            {errors.first && <Validate message={errors.first} />}
                        </FormControl>
                        <FormControl >
                            <Formlabel>Last Name</Formlabel>
                            <FormInput onChange={(e) => setLast(e.target.value)} placeholder="Enter text here" type="text" />
                            {errors.last && <Validate message={errors.last} />}
                        </FormControl>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="baseline"
                        mt="32px"
                    >
                        <FormControl mr={{ md: "5" }}>
                            <Formlabel>Email Address</Formlabel>
                            <FormInput onChange={(e) => setEmail(e.target.value)} placeholder="Enter text here" type="email" />
                            {errors.email && <Validate message={errors.email} />}
                        </FormControl>
                        <FormControl >
                            <Formlabel>Phone Number (Optional)</Formlabel>
                            <FormInput onChange={(e) => setNumber(e.target.value)} placeholder="Enter text here" type="text" />
                        </FormControl>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="baseline"
                        mt="32px"

                    >
                        <FormControl mr={{ md: "5" }}>
                            <Formlabel>Password</Formlabel>
                            <FormInput onChange={(e) => setPassword(e.target.value)} name="password" placeholder="Enter text here" type="password" />
                            {errors.password && <Validate message={errors.password} />}
                        </FormControl>
                        <FormControl >
                            <Formlabel>Confirm Password</Formlabel>
                            <FormInput onChange={(e) => setRepassword(e.target.value)} name="repassword" placeholder="Enter text here" type="password" />
                            {errors.repassword && <Validate message={errors.repassword} />}
                        </FormControl>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="baseline"
                        mt="32px"
                        mb="64px"
                    >
                        <FormControl mr={{ md: "5" }}>
                            <Formlabel>State</Formlabel>
                            <Select onChange={(e) => setState(e.target.value)} name="city" height="72px" placeholder="Select an State" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)", paddingLeft: "20px" }}>
                                {State.map(x =>
                                    <option value={x}>{x}</option>
                                )}
                            </Select>
                            {errors.state && <Validate message={errors.state} />}
                        </FormControl>
                        <FormControl >
                            <Formlabel>City</Formlabel>
                            <FormInput onChange={(e) => setCity(e.target.value)} name="city" placeholder="Enter text here" type="text" />
                            {errors.city && <Validate message={errors.city} />}
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <Box
                alignItems="baseline"
                d="flex"
                backgroundImage={colors.linearBG}
            >
                <Box
                    alignItems="center"
                    maxW={pageWidth}
                    margin="auto"
                    width="100%"
                >
                    <Box
                        d="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="center"
                        mt="64px"
                    >
                        <FormControl mr={{ md: "5" }}>
                            <Formlabel>Current Job Title (Optional)</Formlabel>
                            <FormInput onChange={(e) => setJobtitle(e.target.value)} placeholder="Enter text here" type="text" />
                        </FormControl>
                        <FormControl >
                            <Formlabel>Ideal Jobs (Separate with “,”)</Formlabel>
                            <FormInput onChange={(e) => setIdialJobs(e.target.value)} placeholder="Enter text here" type="text" />
                        </FormControl>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="baseline"
                        mt="32px"
                        mb="64px"
                    >
                        <FormControl mr={{ md: "5" }}>
                            <Formlabel>Resume</Formlabel>
                            <InputGroup >
                                <FormInput placeholder="Drag file here to upload" type="text" value={resumeValue} />
                                <InputRightElement width="12.5rem" top="16px">
                                    <label for="upload">
                                        <input type="file" id="upload" style={{ display: "none" }} onChange={handleChange} />
                                        <Button as={Box} cursor="pointer" color="#fff" bgColor="#59ACA7" px="63px" height="60px" >
                                            UPLOAD
                                        </Button>
                                    </label>
                                </InputRightElement>
                            </InputGroup>
                            {errors.resume && <Validate message={errors.resume} />}
                            <Formlabel fontWeight="normal" mt="24px">* Max upload size: 5MB - PDF + Doc files allowed</Formlabel>
                        </FormControl>
                        <FormControl >
                            <Formlabel>Country Work Authorization Status</Formlabel>
                            <Select onChange={(e) => setWorkauth(e.target.value)} name="workauth" height="72px" placeholder="Select an Country Work Authorization Status" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)", paddingLeft: "20px" }}>
                                <option value="USC, Canadian Citizen">USC, Canadian Citizen</option>
                                <option value="Green Card Holder">Green Card Holder</option>
                                <option value="Need H1 Visa">Have H1 Visa</option>
                                <option value="Employment Auth. Document">Employment Auth. Document</option>
                                <option value="TN Holder">TN Holder</option>
                                <option value="Remote">Remote</option>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <Box
                alignItems="center"
                d="flex"
                backgroundColor={colors.pageBG}
                pt="56px"
                pb="100px"
            >
                <Box
                    alignItems="center"
                    maxW={pageWidth}
                    margin="auto"
                    width="100%"
                >
                    <Box
                        d="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Text fontSize="14" color="#fff">By Signing up, you agree to our <b>Terms and Conditions</b> and<br /><b> Privacy Policy</b></Text>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button onClick={() => handleLogin()} bgColor={colors.secondary} color={colors.text} px="20px" py="30px" mt="32px" >
                            SIGN UP AS TALENT
                        </Button>
                    </Box>
                    <Box
                        d="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        mt="28px"
                    >
                        <Text fontSize={fontSizes.normal} color="#fff">Aready have an account? </Text><CLink as={RLink} to={routes.signInTalent} fontSize={fontSizes.normal} color={colors.secondary}>SIGN IN</CLink>

                    </Box>
                </Box>
            </Box>
        </div >

    )
}
const FormInput = ({ children, ...props }) => {
    return (
        <Input {...props} focusBorderColor="none" height="72px" pl="20px" style={{ backgroundColor: "#fff", color: "rgba(54, 66, 108, 0.5)" }}>
            {children}
        </Input>
    );
};
const Formlabel = ({ children, ...props }) => {
    return (
        <FormLabel {...props} color="#fff" mb="24px" fontWeight="600" >
            {children}
        </FormLabel>
    );
};
export default SignUpTalent
