import Container from '../components/Container';
import { Box, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import React from 'react';
import colors from '../theme/colors';
import { fontSizes, pageWidth } from '../theme/styles';
import { Link as CLink } from '@chakra-ui/layout';
import { Link as RLink } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import { Image } from '@chakra-ui/image';
import image1 from "../assets/images/loadingimage1.png"
import image2 from "../assets/images/loadingimage2.png"
import maskgroup from "../assets/images/maskgroup.png"

const Landing2 = () => {
    window.scrollTo(0, 0)
    return <Container>
        <Box
            alignItems="center"
            d="flex"
            backgroundImage="linear-gradient(180deg, #21273E 0%, #2B334B 100%)"
        >
            <Box
                alignItems="center"
                margin="auto"
                width="100%"
            >
                <Box
                    d="flex"
                    justifyContent={"flex-start"}
                    flexDirection={["column-reverse", "column-reverse", "column-reverse", "row"]}
                    alignItems="center"
                    height="100%"
                >
                    <Box
                        lineHeight={{ md: "58px" }}
                        maxW={pageWidth}
                        py="20px"
                    >
                        <Text p="40px" ml={{ md: "100px" }} fontSize={{ base: "24px", md: "48" }} fontWeight="bold" color="#fff">Let’s get the Dialogge started!</Text>
                    </Box>
                </Box>

            </Box>
        </Box >
        <Box
            alignItems="center"
            d="flex"
            backgroundColor={colors.pageBG}
            pt="100px"
            pb="100px "

        >
            <Box
                alignItems="center"
                maxW={pageWidth}
                margin="auto"
                width="100%"
                d="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection={{ base: 'column-reverse', xl: 'row' }}
            >
                <Box
                >
                    <HeaderText>Our Story</HeaderText>
                    <PText mt="32px" maxWidth="545px" width="100%">Dialogge was born out of the necessity of using today’s technology in giving candidates a voice & for the employers to be able to see beyound a candidate’s resume!<br /><br />

                        Dialogge uses AI-Powered and Digital technology to help bridge the gap between candidates & employees.<br /><br />

                        As we move towards a digital world, Dialogge was born as a platform to give everyone an equal voice & chance to be heard through digital capabilities.<br /><br />

                        Our transcribed videos allow for employers and candidates to be able to search for their ideal match!<br /><br />

                        Our mission & vission was to make the hiring/employees/job hunting process efficient, fast & enjoyable!

                    </PText>
                </Box>
                <Box
                >
                    <Image ml={{ base: "0px", xl: "87px" }} p={{ base: "20px", xl: "0" }} src={image1} height="482px" width="464px" />
                </Box>
            </Box>
        </Box>
        <Box className="bg-image-filter"
            alignItems="center"
            d="flex"
            height="379px"
        >

            <Box
                alignItems="center"
                maxW={pageWidth}
                margin="auto"
                width="100%"
                justifyContent="center"
            >
                <Box
                    maxW="849px"
                    w="100%"
                    margin="auto"
                >
                    <Text fontSize={{ base: "40px", xl: fontSizes.h1 }} fontWeight="bold" color="white" textAlign="center">Contact Us </Text>
                </Box>
            </Box>
        </Box>
        <Box
            alignItems="center"
            d="flex"
            backgroundColor={colors.pageBG}
            pt="96px"
            pb="100px"
        >
            <Box
                alignItems="center"
                maxW={pageWidth}
                margin="auto"
                width="100%"
                d="flex"
                justifyContent="center"
                flexDirection={{ base: 'column-reverse', xl: 'row' }}
            >
                <Box marginY={{ base: '5', xl: '0' }}>
                    <Image src={image2} height="451px" width="497px" padding={{ base: "20px" }} />
                </Box>
                <Box ml="44px">
                    <HeaderText maxWidth="560px" width="100%">
                        Questions? Feedback?
                        We’d love to hear from you.
                    </HeaderText>
                    <PText mt="24px" maxWidth="415px" width="100%">
                        Contact us with any questions & we would be happy to help!
                    </PText>
                    <Button
                        bgColor={colors.secondary}
                        color={colors.primary}
                        px="30px"
                        py="23px"
                        mt="48px"
                    >
                        CONTACT US
                    </Button>
                </Box>
            </Box>

        </Box>

        <Box
            alignItems="center"
            d="flex"
            backgroundImage={colors.linearBG}
            pt="100px"
            pb="100px "

        >
            <Box
                alignItems="center"
                maxW={pageWidth}
                margin="auto"
                width="100%"
            >
                <Box
                    mb="64px"
                >
                    <HeaderText>Frequently Asked Questions</HeaderText>
                </Box>
                <Box

                >
                    <PText mt="48px" fontWeight="bold" maxWidth="875px" width="100%" >Who will see my profile?</PText>
                    <PText pr="50px" mt="24px" maxWidth="875px" width="100%" >Your videos are available to users within the platform. You can share your videos on your other social media platforms as well. Once your videos are posted, they will be visible and searchable.  </PText>

                    <PText mt="58px" fontWeight="bold" maxWidth="875px" width="100%" >How can my videos be searched?</PText>
                    <PText pr="50px" mt="24px" maxWidth="875px" width="100%" >Our video transcribed technology, will transcribe and create a script with keywords to be searched  </PText>

                    <PText mt="58px" fontWeight="bold" maxWidth="875px" width="100%" >Can I share my videos on other platforms?</PText>
                    <PText pr="50px" mt="24px" maxWidth="875px" width="100%" >We are working with other social media platforms to help make sharing easier. At this time, we have created quick links so you can share your videos.  </PText>

                    <PText mt="58px" fontWeight="bold" maxWidth="875px" width="100%" >How does posting jobs work?</PText>
                    <PText pr="50px" mt="24px" maxWidth="875px" width="100%" >If you are an employer, you can create a profile and share details about your ideal candidates, your environment, best culture fit, etc. Once you post a job, you will be able to get candidates apply with their resume and profile link which includes their videos.  </PText>

                </Box>
            </Box>
        </Box>
    </Container >
};
const HeaderText = ({ children, ...props }) => {
    return (
        <Text {...props} fontSize="48px" fontWeight="bold" lineHeight="50px" color="#fff">
            {children}
        </Text>
    );
};
const PText = ({ children, ...props }) => {
    return (
        <Text {...props} fontSize="18px" lineHeight="22px" color="#fff">
            {children}
        </Text>
    );
};
export default Landing2;
